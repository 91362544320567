import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { employeeRolesTypes } from '@shared/helpers/types.helper';
import { IEmployee } from '@shared/interfaces/employee.interface';
import { IError } from '@shared/interfaces/error.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Company } from '@shared/models/company.model';
import { User } from '@shared/models/user.model';
import { CompaniesService } from '@shared/services/companies.service';
import { EmployeesService } from '@shared/services/employees.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { UsersService } from '@shared/services/users.service';
import jwtDecode from 'jwt-decode';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-modals-employees',
  templateUrl: './modals.employees.component.html'
})
export class ModalsEmployeesComponent
  extends ModalDirective<Partial<IEmployee>>
  implements OnInit {
  @Input() public openModal: Subject<Partial<IEmployee>>;

  public defaultCompanyValue: string;
  public defaultUserValue: string;

  public title = String('MODALS.EMPLOYEES.ADD.TITLE');
  public description = String('MODALS.EMPLOYEES.ADD.DESCRIPTION');
  public entry: FormGroup;
  public disableButtons = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;

  public employeeRoles: { value: number; label: string }[] = employeeRolesTypes;
  public errors: IError[] = [];

  public role: number;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    public readonly _companies: CompaniesService,
    public readonly _users: UsersService,

    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _employees: EmployeesService,
    private readonly _toasts: ToastService
  ) {
    super();
    this.employeeRoles = [this.employeeRoles[1], this.employeeRoles[2]];

    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((e: IEmployee) => {
      const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
      this.role = Number(payload.role);

      if (e) {
        if (e.id) {
          this.defaultCompanyValue = e.company.name;
          this.defaultUserValue = e.user.fullName;

          this.title = 'MODALS.EMPLOYEES.EDIT.TITLE';
          this.description = 'MODALS.EMPLOYEES.EDIT.DESCRIPTION';
          this.deleteKey = 'BUTTONS.DELETE';
        } else {
          this.title = 'MODALS.EMPLOYEES.ADD.TITLE';
          this.description = 'MODALS.EMPLOYEES.ADD.DESCRIPTION';

          this.defaultCompanyValue = undefined;
          this.defaultUserValue = undefined;
          this.deleteKey = null;
        }

        this.entry.patchValue({ user: { isActive: true } });
      } else {
        this.entry.patchValue({ user: { isActive: true } });
      }

      const id = this.entry.get('user')['controls']['id'];
      const firstName = this.entry.get('user')['controls']['firstName'];
      const lastName = this.entry.get('user')['controls']['lastName'];
      const email = this.entry.get('user')['controls']['email'];

      // Builing manager
      if (this.role === 1) {
        this.entry.get('company').patchValue({
          id: payload.company
        });

        id.setValidators([]);
        firstName.setValidators([Validators.required]);
        lastName.setValidators([Validators.required]);
        email.setValidators([Validators.email, Validators.required]);
      } else {
        id.setValidators([Validators.required]);
        firstName.setValidators([]);
        firstName.setValidators([]);
        email.setValidators([Validators.email]);
      }

      id.updateValueAndValidity();
      firstName.updateValueAndValidity();
      lastName.updateValueAndValidity();
      email.updateValueAndValidity();
    });
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.disableButtons = true;
      this.entry.disable();

      if (value.user.id) {
        this.postPatchEmployee(value);
      } else {
        // post user
        this.postUser(value.user);
      }
    }
  }

  public onGetValueFromAttributes(e: any) {
    if (e instanceof Company) {
      return e.name;
    } else {
      return `${e.fullName} ${e.email ? '(' + e.email + ')' : null}`;
    }
  }

  public onMatOptionSelect(e: any): void {
    let type = 'user';
    if (e instanceof Company) {
      type = 'company';
    }

    this.entry.get(type).patchValue({
      id: e.id
    });
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      role: ['', [Validators.required]],
      company: this._fb.group({
        id: ['', [Validators.required]]
      }),
      user: this._fb.group({
        id: [''],
        firstName: [''],
        lastName: [''],
        email: [''],
        isActive: [true, [Validators.required]]
      })
    });
  }

  private postPatchEmployee(value) {
    let method = 'post';
    let url = `${method.toUpperCase()} /employees`;
    if (value.id) {
      method = 'patch';
      url = `${method.toUpperCase()} /employees/${value.id}`;
    }

    this._employees[method](value).subscribe(
      (res: IEmployee) => {
        this._logger.info(this.constructorName, url, res);

        this.entry.enable();
        this.entry.reset();
        this.disableButtons = false;

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.disableButtons = false;
        this.entry.enable();
      }
    );
  }

    

  private postUser(value) {
    const url = 'POST /users';

    const passwordLength = 16;
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()-_=+[]{}|;:,.<>?/';
    const allChars = uppercase + lowercase + numbers + specialChars;


    const pw = (
      uppercase[Math.floor(Math.random() * uppercase.length)] + // At least one uppercase
      numbers[Math.floor(Math.random() * numbers.length)] + // At least one number
      specialChars[Math.floor(Math.random() * specialChars.length)] + // At least one special char
      Array.from({ length: passwordLength - 3 }, () => allChars[Math.floor(Math.random() * allChars.length)]).join('') // Remaining characters
    ).split('').sort(() => Math.random() - 0.5).join('');
    
    const user: IUser = new User({
      ...value,
      password: pw,
      role: 1
    });

    this._users.post(user).subscribe(
      (res: IUser) => {
        this._logger.info(this.constructorName, url, res);

        this.entry.get('user').patchValue({
          id: res.id
        });

        this.postPatchEmployee(this.entry.value);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.disableButtons = false;
        this.entry.enable();
      }
    );
  }

  private delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /employees/${id}`;

    this._employees.delete(id).subscribe(
      (res: IEmployee) => {
        this._logger.info(this.constructorName, url, res);

        this.entry.enable();
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.entry.enable();
      }
    );
  }
}
