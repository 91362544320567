import { companies } from '@shared/serializers/companies.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { users } from '@shared/serializers/users.serializer';

export const locations = {
  type: 'location',
  attributes: [
    'label',
    'notes',
    'ageOfRoof',
    'user',
    'secondaryManagers',
    'subLocationManagers',
    'company',
    'parent',
    'sublocationsCount',
    'eventsCount',
    'address',
    'picture',
    'createdAt',
    'updatedAt',
    'parentLocation',
    'energyStarPropertyId',
    'language'
  ],
  company: {
    ref: 'id',
    attributes: companies.attributes,
    included: false
  },
  parent: {
    ref: 'id',
    attributes: ['label',
      'notes',
      'user',
      'secondaryManagers',
      'subLocationManagers',
      'company',
      'parent',
      'sublocationsCount',
      'eventsCount',
      'address',
      'createdAt',
      'updatedAt',
      'language',
      'parentLocation'],
    included: false
  },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  }
};

export const LocationsSerializer = getSerializer(locations);
