import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { appEnvironment } from '@app/environment/environment.app';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
declare global {
  interface Window {
    $crisp: any[];
    CRISP_WEBSITE_ID: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {
  public googleAnalytics: any = appEnvironment.GOOGLE_ANALYTICS;
  public crisp = String(appEnvironment.CRISP);

  constructor(
    @Inject(DOCUMENT) private readonly _document: any,
    private readonly _commonEnvironments: CommonEnvironmentsService
  ) {}

  public setScripts() {
    if (this._commonEnvironments.IS_PRODUCTION) {
      const head = this._document.getElementsByTagName('head')[0];

      // Script do Google Analytics
      const scriptGA = this._document.createElement('script');
      scriptGA.type = 'text/javascript';
      scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalytics.trackingId}`;
      scriptGA.onload = () => {
        // Quando o script for carregado, podemos configurar o gtag sem usar inline scripting
        const scriptGATag = this._document.createElement('script');
        scriptGATag.type = 'text/javascript';
        scriptGATag.textContent = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${this.googleAnalytics.trackingId}');
        `;
        head.appendChild(scriptGATag);
      };
      head.appendChild(scriptGA);

      

      // Script do Crisp Chat
      const scriptCrisp = this._document.createElement('script');
      scriptCrisp.type = 'text/javascript';
      scriptCrisp.src = `https://client.crisp.chat/l.js`;
      scriptCrisp.async = true;
      scriptCrisp.onload = () => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = this.crisp;
      };
      head.appendChild(scriptCrisp);
    }
  }
}
