import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-group-classic',
  templateUrl: './form-group-classic.component.html'
})
export class FormGroupClassicComponent implements OnInit {
  @Input() public value: any;
  @Input() public fieldName = String('');
  @Input() public fieldLabel = String('');
  @Input() public isRequired = Boolean(true);
  @Input() public errors: { label: string; field: string }[] = [
    { field: 'required', label: ' (required)' },
    { field: 'email', label: ' (not an email)' },
    { field: 'MatchPassword', label: ' (not identical)' },
    { field: 'WeakPassword', label: ' (Must have 8+ chars, 1 uppercase, 1 number, 1 special char.)' },
    { field: 'loginFailed', label: ' Incorrect email or password' },
    { field: 'forgetFailed', label: ` Account doesn't exist` },
    { field: 'registerFailed', label: ' Email already exists' }
  ];

  constructor() {}

  ngOnInit(): void {}
}
