import { Component, OnInit } from '@angular/core';
import { EnvironmentsService } from '@app/shared/services/environments.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdatesService } from '@shared/services/updates.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _environments: EnvironmentsService,
    private readonly _translate: TranslateService,
    private readonly _updates: UpdatesService
  ) {
    this.initializeApp();
  }

  ngOnInit(): void {
  }

  private initializeApp() {
    ['en', 'fr'].forEach(lang => {
      this._translate.getTranslation(lang).subscribe(translations => {
        localStorage.setItem(lang, JSON.stringify(translations)),
        this._translate.setTranslation(lang, translations, true);
      });
    });
    this._translate.setDefaultLang('en');
    this._translate.use('en');
    this._environments.setScripts();
    this._updates.checkUpdates();
  }
}
