import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core/lib/translate.service';

import { IChecklist } from '@shared/interfaces/checklist.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IMeter } from '@shared/interfaces/meter.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { IUser } from '@shared/interfaces/user.interface';

export class Meter implements IMeter {
  id: string;
  parentLocation: ILocation;
  subLocation: ILocation;
  submittedBy: IUser;
  checklist: IChecklist;
  pictures: any[];
  status: string;
  meterType: string;
  meterUnit: string;
  value: string;
  isPaused: boolean;
  isValidated?: boolean;
  isDeleted: boolean;
  createdAt: Date;
  previousUsage?: number;
  usage?: number;

  constructor(partial: Partial<any>,
    ) {
    Object.assign(this, partial);
  }

  public getTranslated(label: string): string {
    const userSettings = localStorage.getItem('app.onsitehq.co|usersettings');
    const lang = userSettings ? JSON.parse(userSettings)?.language : 'en'; // Default to English if not set
    const translations = localStorage.getItem(lang);
  
    if (!translations) {
      console.warn(`Translations for language "${lang}" not found in localStorage.`);
      return label; // Fallback to original label if translation is missing
    }
  
    try {
      const txt = JSON.parse(translations);
      return txt[label] || label; // Return translation if exists, otherwise return original label
    } catch (error) {
      console.error('Error parsing translations from localStorage:', error);
      return label;
    }
  }


  public get buttonDownload() {
    return {
      icon: 'lnr-picture me-2',
      label: this.getTranslated('COMMON.SEE_PICTURES'),
      class: 'btn-light btn-sm',
      value: this.pictures
    };
  }

  public get isValidatedMeter() {

    let label = 'VALIDATE';
    let className = 'btn-primary btn-sm';

    if (this.isValidated) {
      label = this.getTranslated('COMMON.VALIDATED');
      className = 'ohq-badge-success disabled badge-validated rounded-pill badge';
    } else {
      label = this.getTranslated('COMMON.VALIDATE');
    }

    return {
      label,
      class: className
    };
  }

  public get changePercentage() {
    let value = '';
    let className = '';

    if (this.previousUsage) {
      const change = ((this.usage - this.previousUsage) / this.previousUsage) * 100;

      if (change > 0) {
        className = 'btn-danger';
        value = `+${change.toFixed(2)}% ↑`;
      } else {
        className = 'btn-success';
        value = `${change.toFixed(2)}% ↓`;
      }
    }

    return {
      label: value,
      class: className
    };
  }

}
