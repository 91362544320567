import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import {
  priorities,
  prioritiesFR,
  recurringPriorities,
  recurringPrioritiesFR,
  tasksStatus,
  tasksStatusFR,
  recurringTaskStatus,
  recurringTaskStatusFR,
  taskRecurrences,
  recurrencesFR,
  userTypes,
  userTypesFR,
  weeklyDays,
  weeklyDaysFR,
  hasStartDateOptions,
  hasStartDateOptionsFR,
  hasDueDateOptions,
  hasDueDateOptionsFR,
  hasStartTimeOptions,
  hasStartTimeOptionsFR,
  hasDueTimeOptions,
  hasDueTimeOptionsFR,
  excludeWeekendOptions,
  excludeWeekendOptionsFR,
  customizeDatesOptions,
  customizeDatesOptionsFR,
  monthlyDays,
  yearlyMonths,
  yearlyMonthsFR
} from '@shared/helpers/types.helper';

import { ITask } from '@shared/interfaces/task.interface';
import { IError } from '@shared/interfaces/error.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Employee } from '@shared/models/employee.model';
import { Location } from '@shared/models/location.model';
import { TasksService } from '@shared/services/tasks.service';
import { EmployeesService } from '@shared/services/employees.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { forkJoin, noop, Subject } from 'rxjs';
import { PicturesService } from '@shared/services/pictures.service';
import { IPicture } from '@shared/interfaces/picture.interface';
import { UploaderOptions, UploadInput, UploadOutput } from 'ngx-uploader';
import moment from 'moment-timezone';
import { uploaderOptions } from '@shared/helpers/upload.helper';
import { ContactsService } from '@shared/services/contacts.service';
import { Contact } from '@shared/models/contact.model';
import { UsersService } from '@shared/services/users.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { IDocument } from '@shared/interfaces/document.interface';
import { IContact } from '@shared/interfaces/contact.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';
import tzlookup from 'tz-lookup';
import { AddressesService } from '@shared/services/addresses.service';
import { IAddress } from '@shared/interfaces/address.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { PaginationTasksService } from '@shared/services/pagination/pagination.tasks.service';
import { IRecurringTask } from '@shared/interfaces/recurring-task.interface';
import { RecurringTaskService } from '@shared/services/recurring-tasks.service';
import { LibrariesService } from '@shared/services/libraries.service';
import { ILibrary } from '@shared/interfaces/library.interface';
import { Asset } from '@shared/models/asset.model';
import { AssetsService } from '@shared/services/assets.service';
import { IAsset } from '@shared/interfaces/asset.interface';
import { IAssetCategory } from '@shared/interfaces/asset-category.interface';
import { AssetCategorysService } from '@shared/services/assets-categories.service';
import { IAssetGroup } from '@shared/interfaces/asset-group.interface';
import { AssetGroupsService } from '@shared/services/assets-group.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modals-tasks',
  templateUrl: './modals.tasks.component.html',
  styleUrls: ['./modals.tasks.component.scss']
})
export class ModalsTasksComponent
  extends ModalDirective<ITask>
  implements OnInit {
  @Input() public openModal: Subject<ITask>;
  @Input() public afterClose?: () => any = noop;
  @Input() public checklistId?: string;
  // @Input() public afterSubmit: (any) => any = noop;

  public uploadInput: EventEmitter<UploadInput> =
    new EventEmitter<UploadInput>();
  public options: UploaderOptions = uploaderOptions;

  public defaultLocationValue: string;
  public defaultAssetValue: string;
  public defaultAssetCategoryValue: string;
  public defaultEmployeeValue: string;

  public title = String('MODALS.TASKS.ADD.TITLE');
  public description = String('MODALS.TASKS.ADD.DESCRIPTION');

  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;
  public disableButtons = Boolean(false);
  public startDate: string;
  public priorities: { label: string; value: number }[] = priorities;
  public taskRecurrences: { label: string; value: string }[] = taskRecurrences;
  public weeklyDays: { label: string; value: string }[] = weeklyDays;
  public monthlyDays: { label: string; value: string }[] = monthlyDays;
  public userTypes: { label: string; value: string }[] = userTypes;
  public tasksStatus: { label: string; value: string }[] = tasksStatus;
  public openRecurringTasksModal: Subject<IRecurringTask> = new Subject();
  public recurringTaskStatus: { label: string; value: string }[] =
    recurringTaskStatus;
  public hasStartDateOptions: { label: string; value: boolean }[] =
    hasStartDateOptions;
  public hasStartTimeOptions: { label: string; value: boolean }[] =
    hasStartTimeOptions;
  public hasDueDateOptions: { label: string; value: boolean }[] =
    hasDueDateOptions;
  public hasDueTimeOptions: { label: string; value: boolean }[] =
    hasDueTimeOptions;
  public excludeWeekendOptions: { label: string; value: boolean }[] =
    excludeWeekendOptions;
  public customizeDatesOptions: { label: string; value: boolean }[] =
    customizeDatesOptions;
  public yearlyMonths: { label: string; value: string }[] = yearlyMonths;
  public openDeleteModal: Subject<boolean> = new Subject();
  public file: File;
  public isEditing = Boolean(false);
  public files: NgxFileDropEntry[] = [];
  public filesToUpload: NgxFileDropEntry[] = [];
  public asset: Partial<IAsset>[] = [];
  public assetGroups: Partial<IAssetGroup>[] = [];
  public links: { icon: string; label: string; url: string }[] = [
    {
      icon: 'lnr-exit',
      label: 'logout',
      url: '/logout'
    }
  ];
  public uploadImages: any[] = [];
  public items: any[] = [];
  public libraryList: any[] = [];
  public id = String('');
  public taskForm: any = {};
  public isTenant = Boolean(false);
  public fgSelected: FormGroup;
  public pictureSelected: string;
  public userType = 'employee';
  public dueDate: string;
  public locationsFilters: any[] = [
    {
      key: 'parent',
      value: true
    }
  ];
  public subLocationsFilters: any[] = [
    {
      key: 'parent',
      value: false
    }
  ];

  public assetsFilters: any[] = [
  ];

  public employeesFilters: IFilter[] = [
    {
      key: 'role',
      value: '3'
    }
  ];
  public isLoading = Boolean(true);
  public isUploading = Boolean(false);
  public isDisabled = Boolean(false);
  public loaded = Boolean(false);
  public showDeviceFiles = Boolean(false);
  public entry: FormGroup;
  public locations: ILocation[] = [];
  public timezone = String('');
  public timezoneLocation = String('');
  public currentUserId = String('');
  public documentList: any[] = [];
  public documentListLabels: any[] = [];
  public recurringTask = null;
  public contact: IContact;
  public categories: IAssetCategory[] = [];

  public bsConfig: any = {
    minDate: moment(new Date()).toDate(),
    containerClass: 'ohq-datepicker',
    dateInputFormat: 'MMMM DD YYYY',
    showWeekNumbers: false,
    adaptivePosition: true,
    minMode: 'day'
  };

  public bsConfig2: any = {
    minDate: moment(new Date()).toDate(),
    containerClass: 'ohq-datepicker',
    dateInputFormat: 'MMMM DD YYYY',
    showWeekNumbers: false,
    adaptivePosition: true,
    minMode: 'day'
  };

  public config: any = {
    class: 'modal-lg'
  };

  private readonly constructorName: string = String(this.constructor.name);
  private blob: Blob;

  constructor(
    public readonly _assets: AssetsService,
    public readonly _assetGroups: AssetGroupsService,
    public readonly _locations: LocationsService,
    public readonly _employees: EmployeesService,
    public readonly _contacts: ContactsService,
    private readonly _pictures: PicturesService,
    private readonly _assetsCategory: AssetCategorysService,
    private readonly _tasks: TasksService,
    private readonly _recurringTasks: RecurringTaskService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toasts: ToastService,
    private readonly _users: UsersService,
    private readonly _router: Router,
    public readonly _addresses: AddressesService,
    private readonly _paginationTasks: PaginationTasksService,
    public readonly _libraries: LibrariesService,
    public dom: DomSanitizer,
    private readonly _translate: TranslateService
  ) {
    super();
    this.onUploadOutput = this.onUploadOutput.bind(this);
    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.deletePicture = this.deletePicture.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }

  onChanges(): void { }

  ngOnInit(): void {
    const token = this._commonEnvironments.getToken();
    const payload: IPayload = jwtDecode(token);
    this.currentUserId = payload.id;
    if (payload.role === 5) {
      this.isTenant = true;
      this._contacts.findByEmail(payload.email).subscribe((res: IContact) => {
        this.defaultLocationValue = res?.subLocation?.label
          ? res?.subLocation?.label
          : '';
        this.entry.patchValue({
          location: { id: res?.subLocation?.id },
        });
        this.contact = res;
        const lIds = res.locations.map((loc) => loc.id);
        this.locationsFilters.push({ key: 'locations', value: lIds });
        const url = `GET /contacts/email/${payload.email}`;
        this._logger.info(this.constructorName, url, res);
      });
    }
    this.defaultLocationValue = '';
    this.defaultAssetValue = '';
    this.defaultAssetCategoryValue = '';
    this.defaultEmployeeValue = '';
    this.createForm();
    this.getDocuments();
    this.getAssets();
    this.getAssetGroups();
    this.getCategories();
    this.openModal.subscribe((a: any) => {
      // this.getAssets();
      this.getAssetGroups();
      // this.getCategories();
      this.taskForm = a;
      if (a) {
        if (a.recurringTask) {
          this.title = `Submit ${a.task}`;
          this.description = '';
        }
        if (a.id) {
          this.id = a.id;
          this.title = 'MODALS.TASKS.EDIT.TITLE';
          this.description = 'MODALS.TASKS.EDIT.DESCRIPTION';
          this.deleteKey = 'BUTTONS.DECLINE';
          this.id = a.id;
        }

        this.defaultLocationValue = a?.location?.label
          ? a?.location?.label
          : '';
        this.defaultAssetValue = a?.asset?.name
          ? a?.asset?.name
          : '';


        const startDate =
          !a.startDate || !a.dueDate ? null : moment.unix(a.startDate).toDate();
        const dueDate =
          !a.startDate || !a.dueDate ? null : moment.unix(a.dueDate).toDate();
        if (!a.userType) {
          a.userType = 'employee';
        }
        if (a.id) {
          this.isLoading = true;
          this.libraryList = a?.documents || [];
          const url = `GET /tasks/${this.id}/documents`;
          this._tasks.getDocuments(this.id).subscribe(
            (res: IDocument[]) => {
              this._logger.info(this.constructorName, url, res);
              if (a.recurrence !== 'NONE') {
                if (a?.recurringTask?.id) {
                  this._recurringTasks
                    .getDocuments(a?.recurringTask?.id)
                    .subscribe(
                      (res2: IDocument[]) => {
                        this._logger.info(this.constructorName, url, res2);
                        this.items = [...res, ...res2];
                        this.isLoading = false;
                      },
                      (err: any) => {
                        this._logger.error(this.constructorName, url, err);
                        this.isLoading = false;
                      }
                    );
                } else {
                  this.items = res;
                  this.isLoading = false;
                }
              } else {
                this.items = res;
                this.isLoading = false;
              }
            },
            (err: any) => {
              this._logger.error(this.constructorName, url, err);
              this.isLoading = false;
            }
          );
        }

        if (a.userType === 'contractor' && a.user) {
          this.userType = a.userType;
          const url = `GET /contacts/${a.user?.email}/`;
          this._contacts.findByEmail(a.user?.email).subscribe(
            (res: IContact) => {
              this._logger.info(this.constructorName, url, res);
              this.entry.patchValue({
                id: a.id,
                task: a.task,
                description: a.description,
                priority: a.priority,
                unit: a.unit,
                notes: a.notes,
                status: a.status,
                userType: 'contractor',
                user: res,
                location: a.location,
                startDate,
                startDateTime: startDate,
                dueDate,
                category: a.category,
                dueDateTime: dueDate,
                hasStartDate: a.hasStartDate,
                hasStartTime: a.hasStartTime,
                hasDueDate: a.hasDueDate,
                hasDueTime: a.hasDueTime,
                customizeDates: a.customizeDates,
                documents: a?.documents?.map((item: any) => item?.id),
                weeklyDays: a.weeklyDays,
                monthlyDays: a.monthlyDays,
                yearlyMonths: a.yearlyMonths,
                yearlyRepeat: a.yearlyRepeat,
                asset: a?.asset?.map((item: any) => !!item?.Id ? item?.Id : item?.id),
                assetGroups: a?.assetGroups?.map((item: any) => !!item?.Id ? item?.Id : item?.id ? item?.id : item),
              });

              const parent = a?.location?.parent as any;
              if (parent) {
                this._addresses
                  .findByLocation(
                    parent?.id || (parent?.Id as string) || parent
                  )
                  .subscribe((r: IAddress) => {
                    let t = tzlookup(r.lat, r.lng) as any;
                    if (!t) {
                      t = '';
                    }
                    this.timezone = moment.tz(t).format('z');
                    this.timezoneLocation = t;
                    this.entry.patchValue(
                      {
                        startDateTime: moment(startDate).tz(t).toDate(),
                        dueDateTime: moment(dueDate).tz(t).toDate()
                      },
                      {
                        emitEvent: false,
                        onlySelf: true
                      }
                    );

                    this.startDate = moment
                      .unix(a.startDate)
                      .tz(t)
                      .format('lll');
                    this.dueDate = moment.unix(a.dueDate).tz(t).format('lll');
                  });
              }
              this.onGetValueFromAttributes(res);
              this.onMatOptionSelect(res, false, true);
              this.defaultEmployeeValue = res.email ? res.email : '';
              this.isLoading = false;
              this.loaded = true;
            },
            (err: any) => {
              this._logger.error(this.constructorName, url, err);
              this.isLoading = false;
              this.loaded = true;
            }
          );
        } else {
          this.userType = 'employee';
          this.defaultEmployeeValue = a.user ? a.user?.email : '';
          this.entry.patchValue({
            id: a.id,
            task: a.task,
            description: a.description,
            status: a.status,
            priority: a.priority,
            unit: a.unit,
            notes: a.notes,
            userType: a.userType,
            user: a.user ? a.user : '',
            location: a.location,
            startDate,
            startDateTime: startDate,
            dueDate,
            dueDateTime: dueDate,
            hasStartDate: a.hasStartDate,
            hasStartTime: a.hasStartTime,
            hasDueDate: a.hasDueDate,
            hasDueTime: a.hasDueTime,
            category: a.category,
            documents: a?.documents?.map((item: any) => item?.id),
            customizeDates: a.customizeDates,
            weeklyDays: a.weeklyDays,
            monthlyDays: a.monthlyDays,
            yearlyMonths: a.yearlyMonths,
            yearlyRepeat: a.yearlyRepeat,
            asset: a?.asset?.map((item: any) => !!item?.Id ? item?.Id : item?.id),
            assetGroups: a?.assetGroups?.map((item: any) => !!item?.Id ? item?.Id : item?.id ? item?.id : item),
          });

          const parent = a?.location?.parent as any;
          if (parent) {
            this._addresses
              .findByLocation(parent?.id || (parent?.Id as string) || parent)
              .subscribe((r: IAddress) => {
                let t = tzlookup(r.lat, r.lng) as any;
                if (!t) {
                  t = '';
                }
                this.timezone = moment.tz(t).format('z');
                this.timezoneLocation = t;
                this.entry.patchValue(
                  {
                    startDateTime: moment(startDate).tz(t).toDate(),
                    dueDateTime: moment(dueDate).tz(t).toDate()
                  },
                  {
                    emitEvent: false,
                    onlySelf: true
                  }
                );
                this.startDate = moment.unix(a.startDate).tz(t).format('lll');
                this.dueDate = moment.unix(a.dueDate).tz(t).format('lll');
              });

            this.onGetValueFromAttributes(a.user);
            this.onMatOptionSelect(a, true, false);
          }
        }
        this.loaded = true;
      } else {
        this.userType = 'employee';
        this.createForm();

        this.title = 'MODALS.TASKS.ADD.TITLE';
        this.description = 'MODALS.TASKS.ADD.DESCRIPTION';
        this.defaultEmployeeValue = '';
        this.defaultAssetValue = '';
        this.deleteKey = null;
        this.items = [];
        this.entry.patchValue({
          files: [],
          isSendEmailSelected: false,
          priority: 0,
          startDate: moment(
            new Date(new Date(new Date().setHours(0)).setMinutes(0))
          ).toDate(),
          dueDate: moment(
            new Date(new Date(new Date().setHours(23)).setMinutes(59))
          ).toDate(),
          startDateTime: moment(
            new Date(new Date(new Date().setHours(0)).setMinutes(0))
          ).toDate(),
          dueDateTime: moment(
            new Date(new Date(new Date().setHours(23)).setMinutes(59))
          ).toDate()
        });

        const value = this.entry.getRawValue();
        value.startDate = moment(value.startDate).toDate();
        value.startDateTime = moment(value.startDateTime).toDate();
        value.dueDate = moment(value.dueDate).toDate();
        value.dueDateTime = moment(value.dueDateTime).toDate();
        const sD =
          new Date(
            value.startDate.getFullYear(),
            value.startDate.getMonth(),
            value.startDate.getDate(),
            value.startDateTime.getHours(),
            value.startDateTime.getMinutes(),
            value.startDateTime.getSeconds()
          ).getTime() / 1000;
        const dD =
          new Date(
            value.dueDate.getFullYear(),
            value.dueDate.getMonth(),
            value.dueDate.getDate(),
            value.dueDateTime.getHours(),
            value.dueDateTime.getMinutes(),
            value.dueDateTime.getSeconds()
          ).getTime() / 1000;

        this.startDate = moment(moment(sD * 1000).toDate()).format('lll');
        this.dueDate = moment(moment(dD * 1000).toDate()).format('lll');
      }
      this.loaded = true;

      if (this.isTenant) {
        this.defaultLocationValue = this.contact?.subLocation?.label
          ? this.contact?.subLocation?.label
          : '';
        this.entry.patchValue({
          location: this.contact?.subLocation,
        });
        // let t = tzlookup(this.contact?.subLocation?.parent?.address?.lat, this.contact?.subLocation?.parent?.address?.lat) as any;
        this._addresses
          .findByLocation(this.contact.subLocation?.parent.Id)
          .subscribe((r: IAddress) => {
            let t = tzlookup(r.lat, r.lng) as any;
            if (!t) {
              t = '';
            }
            this.timezone = moment.tz(t).format('z');
            this.timezoneLocation = t;
          });
      }

      this.entry.valueChanges.subscribe(() => {
        const value = this.entry.getRawValue();
        value.startDate = moment(value.startDate).toDate();
        value.startDateTime = moment(value.startDateTime).toDate();
        value.dueDate = moment(value.dueDate).toDate();
        value.dueDateTime = moment(value.dueDateTime).toDate();
        this.bsConfig2 = {
          ...this.bsConfig2,
          minDate: value.startDate
        };

        const sD =
          new Date(
            value.startDate.getFullYear(),
            value.startDate.getMonth(),
            value.startDate.getDate(),
            value.startDateTime.getHours(),
            value.startDateTime.getMinutes(),
            value.startDateTime.getSeconds()
          ).getTime() / 1000;
        const dD =
          new Date(
            value.dueDate.getFullYear(),
            value.dueDate.getMonth(),
            value.dueDate.getDate(),
            value.dueDateTime.getHours(),
            value.dueDateTime.getMinutes(),
            value.dueDateTime.getSeconds()
          ).getTime() / 1000;

        this.startDate = moment(moment(sD * 1000).toDate()).format('lll');
        this.dueDate = moment(moment(dD * 1000).toDate()).format('lll');
        if (this.timezoneLocation) {
          this.startDate = moment(this.startDate)
            .tz(this.timezoneLocation)
            .format('lll');
          this.dueDate = moment(this.dueDate)
            .tz(this.timezoneLocation)
            .format('lll');
        }
      });
    });

    this._translate.onLangChange.subscribe((event) => {
      const isFrench = event.lang === 'fr';
    
      this.priorities = isFrench ? prioritiesFR : priorities;
      this.recurringTaskStatus = isFrench ? recurringTaskStatusFR : recurringTaskStatus;
      this.taskRecurrences = isFrench ? recurrencesFR : taskRecurrences;
      this.weeklyDays = isFrench ? weeklyDaysFR : weeklyDays;
      this.userTypes = isFrench ? userTypesFR : userTypes;
      this.tasksStatus = isFrench ? tasksStatusFR : tasksStatus;
      this.hasStartDateOptions = isFrench ? hasStartDateOptionsFR : hasStartDateOptions;
      this.hasStartTimeOptions = isFrench ? hasStartTimeOptionsFR : hasStartTimeOptions;
      this.hasDueDateOptions = isFrench ? hasDueDateOptionsFR : hasDueDateOptions;
      this.hasDueTimeOptions = isFrench ? hasDueTimeOptionsFR : hasDueTimeOptions;
      this.excludeWeekendOptions = isFrench ? excludeWeekendOptionsFR : excludeWeekendOptions;
      this.customizeDatesOptions = isFrench ? customizeDatesOptionsFR : customizeDatesOptions;
      this.yearlyMonths = isFrench ? yearlyMonthsFR : yearlyMonths;
    });
    

  }

  public getDocuments() {
    this._libraries
      .get(1, [
        {
          key: 'limit',
          value: 300
        }
      ])
      .subscribe((res) => {
        this.isLoading = false;
        this.documentList = res;
        this.documentListLabels = res.map((res2) => res2.label);
      });
  }

  public goToRecurringTask(a?: IRecurringTask) {
    this.closeModal.next();
    this._router.navigate(['/recurring-tasks', a.id]);
  }

  public afterSubmitRecurring() {

  }

  public afterCloseRecurring() {

  }

  public getAssets() {
    if (!this.isTenant) {
      const url = `GET /assets`;
      this._assets.get(1, []).subscribe(
        (res: IAsset[]) => {
          this.asset = res;
        },
        (err) => {
          this._logger.error(this.constructorName, url, err);
        }
      );
    }
  }

  public getAssetGroups() {
    if (!this.isTenant) {
      const url = `GET /asset-groups`;
      this._assetGroups.get(1, []).subscribe(
        (res: IAssetGroup[]) => {
          this.assetGroups = res;
        },
        (err) => {
          this._logger.error(this.constructorName, url, err);
        }
      );
    }
  }

  public openConfirmModal(fg: FormGroup, id: string) {
    this.fgSelected = fg;
    this.pictureSelected = id;
    this.deletePicture();

    // this.openDeleteModal.next();
  }

  public openUpload() {
    const el = document.getElementsByClassName('upload-files-tasks')[0] as any;
    el?.click();
  }

  public getCategories() {
    const url = `GET /assets-categories`;
    this._assetsCategory.get(1, []).subscribe(
      (res: IAssetCategory[]) => {
        this.categories = res;
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public deletePicture(): void {
    const url2 = `DELETE /pictures/${this.pictureSelected}`;
    this._pictures.delete(this.pictureSelected).subscribe(
      (res: IPicture) => {
        this._logger.info(this.constructorName, url2, res);
        this.isLoading = true;
        const url3 = `GET /tasks/${this.id}/documents`;
        this._tasks.getDocuments(this.id).subscribe(
          (res2: IDocument[]) => {
            this._logger.info(this.constructorName, url3, res2);
            this.items = res2;
            this.isLoading = false;
          },
          (err: any) => {
            this._logger.error(this.constructorName, url3, err);
            this.isLoading = false;
          }
        );
      },
      (err: any) => {
        this._logger.error(this.constructorName, url2, err);
        this._toasts.error('Tasks deletion failed');
      }
    );
  }

  public removePicture(i: number) {
    this.filesToUpload.splice(i, 1);
    this.files.splice(i, 1);
    this.uploadImages.splice(i, 1);
  }

  public removeUplodedDocument(id: string) {
    this.libraryList = this.libraryList.filter(item => item.id !== id);
    this.documentList = this.documentList.filter(item => item.id !== id);
    this.entry.patchValue({
      documents: [...this.entry.get('documents').value.filter(item => item !== id)],
      documentList: [...this.entry.get('documentList').value.filter(item => item !== id)],
    });
  }

  public uploadFiles(files: NgxFileDropEntry[]) {
    this.filesToUpload = [...this.filesToUpload, ...files];
    this.errors = [];
    this.isUploading = true;
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.size < 20000000) {
            this.file = file;
            this.files = [...this.files, this.file as any];
            this.uploadImages.push(URL.createObjectURL(file));
            if (this.entry.get('fileNames').value) {
              this.entry.patchValue({
                fileNames: [...this.entry.get('fileNames').value, file.name]
              });
            } else {
              this.entry.patchValue({
                fileNames: [file.name]
              });
            }
          } else {
            this._toasts.error(`Sorry! The file named "${file.name}" exceeds the maximum allowable size of 20MB. Please upload a smaller file to proceed.`);
          }
        });
        this.isUploading = false;
      } else {
        this._toasts.error('Not a file');
        this.isUploading = false;
      }
    }
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    } else {
      this.id = '';
      this.items = [];
      this.uploadImages = [];
      this.filesToUpload = [];
      this.files = [];
      this.documentList = [];
      this.documentListLabels = [];
    }
    // this.resetModal();
    // this.closeModal.next();
  }

  public updateDates() {
    const value = this.entry.getRawValue();
    value.startDate = moment(
      value.startDate ? value.startDate : new Date()
    ).toDate();
    value.startDateTime = moment(
      value.startDateTime ? value.startDateTime : new Date()
    ).toDate();
    value.dueDate = moment(value.dueDate ? value.dueDate : new Date()).toDate();
    value.dueDateTime = moment(
      value.dueDateTime ? value.dueDateTime : new Date()
    ).toDate();
    const sD =
      new Date(
        value.startDate.getFullYear(),
        value.startDate.getMonth(),
        value.startDate.getDate(),
        value.startDateTime.getHours(),
        value.startDateTime.getMinutes(),
        value.startDateTime.getSeconds()
      ).getTime() / 1000;
    const dD =
      new Date(
        value.dueDate.getFullYear(),
        value.dueDate.getMonth(),
        value.dueDate.getDate(),
        value.dueDateTime.getHours(),
        value.dueDateTime.getMinutes(),
        value.dueDateTime.getSeconds()
      ).getTime() / 1000;

    this.startDate = moment(moment(sD * 1000).toDate()).format('lll');
    this.dueDate = moment(moment(dD * 1000).toDate()).format('lll');
    if (this.timezoneLocation) {
      this.startDate = moment(this.startDate)
        .tz(this.timezoneLocation)
        .format('lll');
      this.dueDate = moment(this.dueDate)
        .tz(this.timezoneLocation)
        .format('lll');
    }
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.disableButtons = true;
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /tasks`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /tasks/${value.id}`;
      }

      value = this.entry.getRawValue();

      if (!value.asset) { value.asset = []; }
      if (!value.assetGroups) { value.assetGroups = []; }
      let startDate;
      let dueDate;
      if (value.startDate) {
        value.startDate = moment(value.startDate)
          .tz(this.timezoneLocation)
          .toDate();
        value.startDateTime = moment(
          value.startDateTime && !isNaN(value.startDateTime)
            ? value.startDateTime
            : moment().startOf('day')
        )
          .tz(this.timezoneLocation)
          .toDate();
        startDate =
          new Date(
            value.startDate.getFullYear(),
            value.startDate.getMonth(),
            value.startDate.getDate(),
            value.startDateTime.getHours(),
            value.startDateTime.getMinutes(),
            value.startDateTime.getSeconds()
          ).getTime() / 1000;
      }
      if (value.dueDate) {
        value.dueDate = moment(value.dueDate)
          .tz(this.timezoneLocation)
          .toDate();
        value.dueDateTime = moment(
          value.dueDateTime && !isNaN(value.dueDateTime)
            ? value.dueDateTime
            : moment().endOf('day')
        )
          .tz(this.timezoneLocation)
          .toDate();
        dueDate =
          new Date(
            value.dueDate.getFullYear(),
            value.dueDate.getMonth(),
            value.dueDate.getDate(),
            value.dueDateTime.getHours(),
            value.dueDateTime.getMinutes(),
            value.dueDateTime.getSeconds()
          ).getTime() / 1000;
      }

      value = {
        pictures: [],
        ...value,
        startDate,
        dueDate,
        checklistId: this.checklistId,
        // asset: this.taskForm?.asset ? this.taskForm?.asset : null
      };
      if (this.taskForm?.recurringTask) {
        value.recurringTask = this.taskForm.recurringTask;
      }

      if (value.recurrence !== 'NONE') {
        this.handleRecurrenceTasks(value, method);
      } else {
        this.submitTask(method, value, url);
      }
    }
  }

  public mapPriority(n: number) {
    switch (n) {
      case 0:
        return 'LOW';
      case 1:
        return 'MEDIUM';
      case 2:
        return 'HIGH';
      default:
        return 'LOW';
    }
  }

  public mapRecurringTaskData(data: ITask): any {
    return {
      ...data,
      subject: data?.task,
      subLocation: data?.location,
      priority: this.mapPriority(data.priority)
    };
  }

  public isImage(filename) {
    return filename.split('.').pop() == 'jpg' ||
      filename.split('.').pop() == 'png' ||
      filename.split('.').pop() == 'jpeg'
      ? true
      : false;
  }

  public isImageType(type) {
    const imageTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp'
    ];
    return imageTypes.includes(type);
  }

  public handleRecurrenceTasks(value, method) {
    let promises = [];
    let url = '/post/recurring-tasks';
    if (method === 'patch') {
      url = '/post/recurring-tasks';
    }
    if (!value.id) {
      value.status = 'ACTIVE';
    }

    this.filesToUpload.forEach((f) => {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const filters: any = [
            {
              key: 'label',
              value: file.name
            },
            {
              key: 'assignedUsers',
              value: [this.entry.get('user').value.id]
            }
          ];
          promises.push(this._libraries.postLibrary(file, filters));
        });
      }
    });

    if (this.filesToUpload.length > 0) {
      forkJoin(promises).subscribe(
        (resDocs) => {
          this._toasts.success('Upload successfully created!');
          this.isUploading = false;
          promises = [];
          if (!value?.documents) {
            value.documents = [];
          }
          value.documents = [...value?.documents, ...resDocs.map((item: ILibrary) => item.id), ...value.documentList];
          this.createRecurringTask(value, method, url);
        },
        (error) => {
          this._toasts.error('Upload has failed!');
          console.error(error.message);
        }
      );
    } else {
      if (!value?.documents) {
        value.documents = [];
      }
      value.documents = [...value?.documents, ...value.documentList];
      this.createRecurringTask(value, method, url);
    }
  }

  public submitTask(method, value, url) {
    let promises = [];
    this.filesToUpload.forEach((f) => {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const filters: any = [
            {
              key: 'label',
              value: file.name
            },
            {
              key: 'assignedUsers',
              value: [this.entry.get('user').value.id]
            }
          ];
          promises.push(this._libraries.postLibrary(file, filters));
        });
      }
    });
    if (this.filesToUpload.length > 0) {
      forkJoin(promises).subscribe(
        (resDocs) => {
          this._toasts.success('Upload successfully created!');
          this.isUploading = false;
          promises = [];
          if (!value?.documents) {
            value.documents = [];
          }
          value.documents = [...value?.documents, ...resDocs.map((item: ILibrary) => item.id), ...value.documentList];
          this.createAndUpdateTask(value, method, url);
        },
        (error) => {
          this._toasts.error('Upload has failed!');
          console.error(error.message);
        }
      );
    } else {
      if (!value?.documents) {
        value.documents = [];
      }
      value.documents = [...value?.documents, ...value.documentList];
      this.createAndUpdateTask(value, method, url);
    }
  }

  public createAndUpdateTask(value, method, url) {
    this._tasks[method](value).subscribe(
      (res: ITask) => {
        this._logger.info(this.constructorName, url, res);
        this.entry.enable();
        // this.entry.reset();
        // this.uploadImages = [];
        this.disableButtons = false;
        // this.id = res.id;
        this.isUploading = true;
        if (method === 'post') {
          this._toasts.success('Work Order successfully created!');
        }
        if (method === 'patch') {
          this._toasts.success('Work Order successfully updated!');
        }
        this.setDefaultValues();
        this.resetModal();

        this.resetForm();
        this.closeModal.next();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }
        this.entry.enable();
        this.disableButtons = false;
      }
    );
  }

  public createRecurringTask(value, method, url) {
    this._recurringTasks[method](this.mapRecurringTaskData(value)).subscribe(
      (res: IRecurringTask) => {
        this._logger.info(this.constructorName, url, res);
        this.entry.enable();

        this.disableButtons = false;
        this.isUploading = true;
        if (method === 'post') {
          this._toasts.success('Work Order successfully created!');
        }
        if (method === 'patch') {
          this._toasts.success('Work Order successfully updated!');
        }
        this.setDefaultValues();
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        const errors = err.errors;
        if (errors) {
          this.errors = errors;
          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }
        this.entry.enable();
        this.disableButtons = false;
      }
    );
  }

  public onMatOptionSelect(e: any, isUser = false, isContact = false): void {
    if (e) {
      let type = 'location';
      if (e instanceof Employee || isUser) {
        if (e.user) {
          type = 'user';
          this.entry.get(type).patchValue({
            id: e.user?.id
          });
        }
      } else if (e instanceof Contact || isContact) {
        type = 'user';
        const url = `GET /users/email/${e.email}`;
        this._users.getByEmail(e.email).subscribe(
          (res: IUser) => {
            this._logger.info(this.constructorName, url, res);
            this.entry.get(type).patchValue({
              id: res.id
            });
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);
          }
        );
      } else if (e instanceof Asset) {
        type = 'asset';
        const url = `GET /assets/${e.id}`;
        this._assets.find(e.id).subscribe(
          (res: IAsset) => {
            this._logger.info(this.constructorName, url, res);
            this.entry.get(type).patchValue({
              id: res.id
            });
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);
          }
        );
      } else {
        this.entry.get(type).patchValue({
          id: e.id
        });
      }
      if (type === 'location') {
        this._addresses
          .findByLocation(e.parent?.id || e.parent)
          .subscribe((r: IAddress) => {
            let t = tzlookup(r.lat, r.lng) as any;
            if (!t) {
              t = '';
            }
            this.timezone = moment.tz(t).format('z');
            this.timezoneLocation = t;
            this.updateDates();
          });
      }
    }
  }

  public onDismiss() {
    this.afterClose();
    this.setDefaultValues();
  }

  public onGetValueFromAttributes(e: any) {
    if (e instanceof Location) {
      return `${e.label} (${e.parent.label})`;
    } else if (e instanceof Asset) {
      return `${e.name}`;
    } else {
      return `${e && e.email ? e.email : null}`;
    }
  }

  public selectedDate() {
    const startDate = this.entry.get('startDate').value;
    this.startDate = moment(startDate).format('ll');
  }

  public onUploadOutput(output: UploadOutput) {
    switch (output.type) {
      case 'addedToQueue':
        this._logger.info(this.constructorName, 'File added to queue', output);
        const file = output.file;
        this.blob = new Blob([file.nativeFile], { type: file.type });

        this.startUpload();
        break;
    }
  }

  public startUpload(): void {
    if (this.blob) {
      this.isUploading = true;
      const filters: any = [
        {
          key: 'ownerType',
          value: 'User'
        },
        {
          key: 'type',
          value: 'profile'
        }
      ];
      const url = `POST /pictures`;
      this._pictures.post(filters, this.blob).subscribe(
        (res: IPicture) => {
          this._logger.info(this.constructorName, url, res);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);
          this._toasts.success('User update failed');
        }
      );
    }
  }

  public changeWeeklyDays(d: any) {
    const index = this.entry.get('weeklyDays').value.indexOf(d.value);
    if (index >= 0) {
      const values = this.entry.get('weeklyDays').value;
      values.splice(index, 1);
      this.entry.patchValue({ weeklyDays: values });
    } else {
      const values = this.entry.get('weeklyDays').value;
      values.push(d.value);
      this.entry.patchValue({ weeklyDays: values });
    }
  }

  public changeYearlyMonths(d: any) {
    const index = this.entry.get('yearlyMonths').value.indexOf(d.value);
    if (index >= 0) {
      const values = this.entry.get('yearlyMonths').value;
      values.splice(index, 1);
      this.entry.patchValue({ yearlyMonths: values });
    } else {
      const values = this.entry.get('yearlyMonths').value;
      values.push(d.value);
      this.entry.patchValue({ yearlyMonths: values });
    }
  }

  public setDefaultValues() {
    this.id = '';
    this.items = [];
    this.files = [];
    this.documentList = [];
    this.assetGroups = [];
    this.documentListLabels = [];
    this.uploadImages = [];
    this.libraryList = [];
    this.uploadImages = [];
    this.filesToUpload = [];
    this.entityForm.reset();
    this.errors = [];
    this.createForm();
    this.title = 'MODALS.TASKS.ADD.TITLE';
    this.description = 'MODALS.TASKS.ADD.DESCRIPTION';
    this.defaultLocationValue = '';
    this.defaultEmployeeValue = '';
    this.defaultAssetValue = '';
    this.deleteKey = null;
    this.entry.enable();
    this.entry.patchValue({
      assetGroups: [],
      documentList: [],
      documents: [],
      files: [],
      user: { id: null },
      location: { id: null },
      isSendEmailSelected: false,
      priority: 0,
      startDate: moment(
        new Date(new Date(new Date().setHours(0)).setMinutes(0))
      ).toDate(),
      dueDate: moment(
        new Date(new Date(new Date().setHours(23)).setMinutes(59))
      ).toDate(),
      startDateTime: moment(
        new Date(new Date(new Date().setHours(0)).setMinutes(0))
      ).toDate(),
      dueDateTime: moment(
        new Date(new Date(new Date().setHours(23)).setMinutes(59))
      ).toDate()
    });

    const value = this.entry.getRawValue();
    value.startDate = moment(value.startDate).toDate();
    value.startDateTime = moment(value.startDateTime).toDate();
    value.dueDate = moment(value.dueDate).toDate();
    value.dueDateTime = moment(value.dueDateTime).toDate();
    const sD =
      new Date(
        value.startDate.getFullYear(),
        value.startDate.getMonth(),
        value.startDate.getDate(),
        value.startDateTime.getHours(),
        value.startDateTime.getMinutes(),
        value.startDateTime.getSeconds()
      ).getTime() / 1000;
    const dD =
      new Date(
        value.dueDate.getFullYear(),
        value.dueDate.getMonth(),
        value.dueDate.getDate(),
        value.dueDateTime.getHours(),
        value.dueDateTime.getMinutes(),
        value.dueDateTime.getSeconds()
      ).getTime() / 1000;

    this.startDate = moment(moment(sD * 1000).toDate()).format('lll');
    this.dueDate = moment(moment(dD * 1000).toDate()).format('lll');
  }

  public changeMonthlyDays(d: any) {
    const index = this.entry.get('monthlyDays').value.indexOf(d.value);
    if (index >= 0) {
      const values = this.entry.get('monthlyDays').value;
      values.splice(index, 1);
      this.entry.patchValue({ monthlyDays: values });
    } else {
      const values = this.entry.get('monthlyDays').value;
      values.push(d.value);
      this.entry.patchValue({ monthlyDays: values });
    }
  }

  public changeRecurrence() {
    if (!this.id && this.entry.get('recurrence').value === 'WEEKLY') {
      this.entry.patchValue({
        weeklyDays: ['FRI']
      });
    } else if (!this.id && this.entry.get('recurrence').value === 'YEARLY') {
      this.entry.patchValue({
        yearlyMonths: ['DEC']
      });
    } else if (!this.id && this.entry.get('recurrence').value === 'DAILY') {
      this.entry.patchValue({
        weeklyDays: ['MON', 'TUE', 'WED', 'THU', 'FRI']
      });
    }
    if (!this.id) {
      if (
        this.entry.get('recurrence').value !== 'NONE' &&
        this.entry.get('recurrence').value !== 'N'
      ) {
        this.entry.patchValue({
          dueDate: moment(
            new Date().setFullYear(new Date().getFullYear() + 30)
          ).toDate(),
          dueDateTime: moment(
            new Date(
              new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              ).setHours(23)
            ).setMinutes(59)
          ).toDate()
        });
      }
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      files: [''],
      fileNames: [''],
      task: ['', [Validators.required]],
      description: ['', [Validators.required]],
      priority: ['LOW', [Validators.required]],
      recurrence: [taskRecurrences[0].value, [Validators.required]],
      status: [tasksStatus[0].value, []],
      unit: new FormControl(''),
      notes: new FormControl(''),
      yearlyRepeat: new FormControl(1),
      userType: [this.userType, [Validators.required]],
      user: this._fb.group({
        id: ['', !this.isTenant ? [Validators.required] : []]
      }),
      subLocation: this._fb.group({
        id: ['', []]
      }),
      location: this._fb.group({
        id: ['', [Validators.required]]
      }),
      asset: ['', []],
      category: this._fb.group({
        id: ['']
      }),
      assetGroups: [[], []],
      startDate: [new Date(), []],
      startDateTime: [
        new Date(new Date(new Date().setHours(0)).setMinutes(0)),
        []
      ],
      dueDate: [new Date(), []],
      dueDateTime: [
        new Date(new Date(new Date().setHours(23)).setMinutes(59)),
        []
      ],
      hasStartDate: [false, []],
      hasStartTime: [false, []],
      hasDueDate: [false, []],
      hasDueTime: [false, []],
      customizeDates: [false, []],
      weeklyDays: [[], []],
      monthlyDays: [[], []],
      yearlyMonths: [[], []],
      documentList: [[], []],
      documents: [[], []],
      // pictures: [[]],
    });
  }

  private delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /tasks/${id}`;

    this._tasks.delete(id).subscribe(
      (res: ITask) => {
        this._logger.info(this.constructorName, url, res);

        // this.reset();
        // this._paginationTasks.reloadPage(1);
        // this._router.navigate(['/tasks']);
        this.closeModal.next();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.entry.enable();
      }
    );
  }

  private reset() {
    this.disableButtons = false;
    this.entry.enable();
    // this.resetModal();
  }
}
