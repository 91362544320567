import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '@shared/components/card/card.component';
import { ContentComponent } from '@shared/components/content/content.component';
import { ErrorComponent } from '@shared/components/error/error.component';
import { FormGroupClassicComponent } from '@shared/components/form-group-classic/form-group-classic.component';
import { FormGroupComponent } from '@shared/components/form-group/form-group.component';
import { ListErrorsComponent } from '@shared/components/list-errors/list-errors.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { ModalsActionsComponent } from '@shared/components/modals/actions/modals.actions.component';
import { ModalsTasksComponent } from '@shared/components/modals/tasks/modals.tasks.component';
import { ModalsConfirmComponent } from '@shared/components/modals/confirm/modals.confirm.component';
import { ModalsContactsComponent } from '@shared/components/modals/contacts/modals.contacts.component';
import { ModalsEventsComponent } from '@shared/components/modals/events/modals.events.component';
import { ModalsLibrariesComponent } from '@shared/components/modals/libraries/modals.libraries.component';
import { ModalsImportComponent } from '@shared/components/modals/import/modals.import.component';
import { ModalsItemGroupsComponent } from '@shared/components/modals/item-groups/modals.item-groups.component';
import { ModalsItemsComponent } from '@shared/components/modals/items/modals.items.component';
import { ModalsLocationsComponent } from '@shared/components/modals/locations/modals.locations.component';
import { ModalsComponent } from '@shared/components/modals/modals.component';
import { ModalsQRCodesComponent } from '@shared/components/modals/qrcodes/modals.qrcodes.component';
import { ModalsTemplatesComponent } from '@shared/components/modals/templates/modals.templates.component';
import { ModalsUsersComponent } from '@shared/components/modals/users/modals.users.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { PhoneNumberComponent } from '@shared/components/phone-number/phone-number.component';
import { ScoreComponent } from '@shared/components/score/score.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';
import { TableComponent } from '@shared/components/table/table.component';
import { TypeaheadComponent } from '@shared/components/typeahead/typeahead.component';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { SidebarOnScrollDirective } from '@shared/directives/sidebar.on-scroll.directive';
import { AgoPipe } from '@shared/pipes/ago.pipe';
import { DatesPipe } from '@shared/pipes/dates.pipe';
import { DocumentsFilterPipe } from '@shared/pipes/document.pipe';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SidebarModule } from 'ng-sidebar';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { NgxSelectModule } from 'ngx-select-ex';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ModalsReportsComponent } from './components/modals/reports/modals.reports.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { CommentComponent } from './components/comment/comment.component';
import { CommentFormComponent } from './components/commentForm/commentForm.component';
import { CommentsComponent } from './components/comments/comments.component';
import { ThreadCommentsComponent } from './components/thread-comments/thread-comments.component';
import { ThreadCommentFormComponent } from './components/thread-comment-form/thread-comment-form.component';
import { ThreadCommentComponent } from './components/thread-comment/thread-comment.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalsAssetsComponent } from './components/modals/assets/modals.assets.component';
import { ModalsRecurringTasksComponent } from './components/modals/recurring-tasks/modals.recurring-tasks.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CategoryFilterPipe } from './pipes/category.pipe';
import { ModalsWriteMessageComponent } from './components/modals/write-message/modals.write-message.component';

import { TagInputModule } from 'ngx-chips';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxEditorModule } from 'ngx-editor';
import { ModalsAssetGroupsComponent } from './components/modals/asset-groups/modals.asset-groups.component';

const components = [
  ContentComponent,
  CommentComponent,
  CommentsComponent,
  ThreadCommentsComponent,
  ThreadCommentFormComponent,
  ThreadCommentComponent,
  CommentFormComponent,
  ErrorComponent,
  ListErrorsComponent,
  CardComponent,
  LoadingComponent,
  NotFoundComponent,
  FormGroupComponent,
  FormGroupClassicComponent,
  PhoneNumberComponent,
  ScoreComponent,
  SidebarComponent,
  TableComponent,
  TypeaheadComponent,
  CalendarComponent,

  // Modals
  ModalsComponent,
  ModalsActionsComponent,
  ModalsTasksComponent,
  ModalsRecurringTasksComponent,
  ModalsAssetsComponent,
  ModalsAssetGroupsComponent,
  ModalsContactsComponent,
  ModalsConfirmComponent,
  ModalsLibrariesComponent,
  ModalsLocationsComponent,
  ModalsEventsComponent,
  ModalsQRCodesComponent,
  ModalsTemplatesComponent,
  ModalsImportComponent,
  ModalsItemsComponent,
  ModalsItemGroupsComponent,
  ModalsUsersComponent,
  ModalsReportsComponent,
  ModalsWriteMessageComponent,

  // Directive
  AutofocusDirective,
  ButtonDirective,
  SidebarOnScrollDirective,

  // Pipes
  AgoPipe,
  DatesPipe,
  DocumentsFilterPipe,
  CategoryFilterPipe
];

const modules = [
  InfiniteScrollModule,
  NgxUploaderModule,
  BsDatepickerModule,
  BsDropdownModule,
  ButtonsModule,
  CollapseModule,
  CommonModule,
  GooglePlaceModule,
  PhotoGalleryModule,
  FormsModule,
  HttpClientModule,
  ModalModule,
  NgCircleProgressModule,
  NgxFileDropModule,
  NgxMaskModule,
  NgxSelectModule,
  PaginationModule,
  RouterModule,
  ReactiveFormsModule,
  TabsModule,
  PopoverModule,
  QuillModule,
  SidebarModule,
  TimepickerModule,
  TooltipModule,
  TypeaheadModule,
  UiSwitchModule,
  FullCalendarModule, 
  TagInputModule,
  AngularEditorModule,
  NgxEditorModule,
];

@NgModule({
  declarations: [...components],
  imports: [...modules, TranslateModule.forChild()],
  exports: [...modules, ...components, TranslateModule]
})
export class SharedModule { }
