/* eslint-disable max-len */
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDirective } from '@shared/directives/modal.directive';
import { uploaderOptions } from '@shared/helpers/upload.helper';
import { IContact } from '@shared/interfaces/contact.interface';
import { IEmployee } from '@shared/interfaces/employee.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILibraryCategory } from '@shared/interfaces/library-category.interface';
import { ILibrary, PermissionType } from '@shared/interfaces/library.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { ITask } from '@shared/interfaces/task.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { AddressesService } from '@shared/services/addresses.service';
import { ContactsService } from '@shared/services/contacts.service';
import { EmployeesService } from '@shared/services/employees.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LibrariesCategoriesService } from '@shared/services/libraries-categories.service';
import { LibrariesService } from '@shared/services/libraries.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationLibrariesService } from '@shared/services/pagination/pagination.libraries.service';
import { ToastService } from '@shared/services/toast.service';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { NgxSelectOption } from 'ngx-select-ex';
import { UploaderOptions, UploadInput } from 'ngx-uploader';
import { forkJoin, noop, Observable, Subject } from 'rxjs';
import { promise } from 'selenium-webdriver';

@Component({
  selector: 'app-modals-libraries',
  templateUrl: './modals.libraries.component.html',
  styleUrls: ['./modals.libraries.component.scss'],
})
export class ModalsLibrariesComponent
  extends ModalDirective<ILibrary>
  implements OnInit {
  @Input() public openModal: Subject<ILibrary>;
  @Input() public location?: ILocation;
  @Input() public afterClose?: () => any = noop;
  @Input() public afterSubmit: (any) => any = noop;

  public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
  public options: UploaderOptions = uploaderOptions;
  public openTasksModal: Subject<ITask> = new Subject();

  public defaultParentLocationValue: string;
  public defaultSubLocationValue: string;
  public defaultCategoryValue: string;
  public title = String('MODALS.LIBRARIES.ADD.TITLE');
  public description = String('MODALS.LIBRARIES.ADD.DESCRIPTION');

  public file: File;
  public files: NgxFileDropEntry[] = [];

  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;
  public disableButtons = Boolean(false);
  public openDeleteModal: Subject<boolean> = new Subject();
  public isEditing = Boolean(false);
  public id = null;
  public isTenant = Boolean(false);
  public fgSelected: FormGroup;
  public categoryFilters: any[] = [];
  public libraries: any[] = [];
  public type = 'Document';

  public locationsFilters: any[] = [
    {
      key: 'parent',
      value: false
    }
  ];
  public subLocationsFilters: any[] = [
    {
      key: 'parent',
      value: true
    }
  ];

  public employeesFilters: IFilter[] = [
    {
      key: 'role',
      value: '3'
    }
  ];

  public isLoading = Boolean(true);
  public isUploading = Boolean(false);
  public isDisabled = Boolean(false);
  public entry: FormGroup;
  public locations: ILocation[] = [];
  public timezone = String('');
  public timezoneLocation = String('');
  public currentUserId = String('');
  public documentSelected: string;
  public assignedUsers: Partial<IUser>[];
  public categories: ILibraryCategory[];
  public permissionTypes: PermissionType[] = Object.values(PermissionType)
    .filter(value => typeof value === 'string') as PermissionType[];

  public bsConfig: any = {
    containerClass: 'ohq-datepicker',
    dateInputFormat: 'MMMM DD YYYY',
    showWeekNumbers: false,
    adaptivePosition: true,
    minMode: 'day'
  };
  public config: any = {
    class: 'modal-lg'
  };
  public closeModal: Subject<any> = new Subject<any>();
  private readonly constructorName: string = String(this.constructor.name);
  private blob: Blob;

  constructor(
    public readonly _librariesCategories: LibrariesCategoriesService,
    public readonly _locations: LocationsService,
    public readonly _employees: EmployeesService,
    public readonly _contacts: ContactsService,
    public readonly _libraries: LibrariesService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toasts: ToastService,
    public readonly _addresses: AddressesService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _paginationLibraries: PaginationLibrariesService,
    private readonly _router: Router,
    public dom: DomSanitizer
  ) {
    super();
    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onMatOptionSelectSubLocation = this.onMatOptionSelectSubLocation.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }

  ngOnInit(): void {
    const token = this._commonEnvironments.getToken();
    const payload: IPayload = jwtDecode(token);
    this.createForm();

    if (payload.role !== 5) {
      this.getEmployees();
    }

    this.getCategories();
    this.currentUserId = payload.id;

    this.openModal.subscribe((a: ILibrary) => {

      if (payload.role === 5) {
        this.isTenant = true;
        this.description = String('MODALS.LIBRARIES.ADD_TENANT.DESCRIPTION');
        this._contacts.findByEmail(payload.email).subscribe((res: IContact) => {
          this.defaultParentLocationValue = res?.subLocation?.parent?.label
            ? res?.subLocation?.parent?.label
            : '';
          this.entry.patchValue({
            parentLocation: { id: res?.subLocation?.parent?.Id || res?.subLocation?.parent?.id },
            subLocation: { id: res?.subLocation?.id },
          });
          this.defaultSubLocationValue = res?.subLocation?.label ? res?.subLocation?.label : '';
          // this.contact = res;
          // const lIds = res.locations.map((loc) => loc.id);
          // this.locationsFilters.push({ key: 'locations', value: lIds });
          const url = `GET /contacts/email/${payload.email}`;
          this._logger.info(this.constructorName, url, res);
        });
      }

      if (!!this.location) { // location page and documents tab
        this.entry.patchValue({
          parentLocation: { id: this.location.id },
        });
        this.onMatOptionSelect(this.location);
        this.defaultParentLocationValue = this.location?.label ? this.location.label : '';
      }
      if (a) {
        if (a?.id) {
          this.isLoading = true;
          this.id = a.id;
          if (a.parentLocation) {
            a.parentLocation.id = a.parentLocation?.Id ? a.parentLocation?.Id : a.parentLocation?.id;
            this.onMatOptionSelect(a.parentLocation);
            this.defaultParentLocationValue = a?.parentLocation?.label ? a?.parentLocation?.label : '';
          }
          if (a.subLocation) {
            a.subLocation.id = a.subLocation?.Id ? a.subLocation?.Id : a.subLocation?.id;
            this.onMatOptionSelectSubLocation(a.subLocation);
            this.defaultSubLocationValue = a?.subLocation?.label ? a?.subLocation?.label : '';
          }
          if (a.category) {
            this.defaultCategoryValue = a?.category?.name ? a?.category?.name : '';
            a.category.id = a.category?.id;
          }
          this.title = 'MODALS.LIBRARIES.EDIT.TITLE';
          this.description = 'MODALS.LIBRARIES.EDIT.DESCRIPTION';
          this.deleteKey = 'BUTTONS.DELETE';
          this.entry.patchValue({
            id: a.id,
            comment: a.comment,
            label: a.label,
            parentLocation: a?.parentLocation,
            subLocation: a?.subLocation,
            assignedUsers: a?.assignedUsers,
            accessPermission: a.accessPermission,
            category: a?.category,
            subCategory: a?.subCategory
          });
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    });
  }

  public getCategories() {
    const url = `GET /library-categories`;
    this._librariesCategories.get(1, []).subscribe(
      (res: ILibraryCategory[]) => {
        this.categories = res;
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
      this.id = '';
    } else if (type === 'DELETE') {
      this.delete();
    } else {
      this.id = '';
    }
  }

  public uploadFiles(files: NgxFileDropEntry[]) {
    this.errors = [];
    this.isUploading = true;
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.size < 20000000) {
            this.files.push(f);
            this.addNewFileRow(file);
          } else {
            this._toasts.error(`Sorry! The file named "${file.name}" exceeds the maximum allowable size of 20MB. Please upload a smaller file to proceed.`);
          }
        });
      } else {
        this._toasts.error('Not a file');
      }
    }
  }

  public removeDocument(i: number) {
    this.files.splice(i, 1);
  }

  public openConfirmModal() {
    this.openDeleteModal.next();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {

      this.isLoading = true;
      this.disableButtons = true;
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /libraries`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /libraries/${value.id}`;
      }
      value = this.entry.getRawValue();
      value.assignedUsers = (value?.assignedUsers || []).map(id => ({ id }));
      if (value.id) {
        this._libraries.patch(value).subscribe(() => {
          this._toasts.success('Document successfully updated!');
          this.isLoading = false;
          this.resetForm();
          this.closeModal.next();
        }, (error) => {
          this._toasts.error('Update has failed!');
          console.error(error.message);
        });
      } else {
        let promises = [];
        this.files.forEach((f, index) => {
          if (f.fileEntry.isFile) {
            const filters: any = [
              {
                key: 'parentLocation',
                value: this.entry.get('parentLocation').value.id
              },
              {
                key: 'subLocation',
                value: this.entry.get('subLocation').value.id
              },
              {
                key: 'category',
                value: this.entry.get('category').value.id
              },
              {
                key: 'isMobileVisible',
                value: this.entry.get('isMobileVisible').value
              },
              {
                key: 'subCategory',
                value: this.entry.get('subCategory').value
              },
              {
                key: 'label',
                value: this.entry.get('fileList').value[index]?.label
              },
              {
                key: 'comment',
                value: this.entry.get('fileList').value[index]?.comment
              },
              {
                key: 'assignedUsers',
                value: this.entry.get('assignedUsers').value
              },
              {
                key: 'accessPermission',
                value: this.entry.get('accessPermission').value
              },
            ];

            const fileEntry = f.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
              promises.push(this._libraries.postLibrary(file, filters));
            });
          }
        });
        forkJoin(promises).subscribe(() => {
          this._toasts.success('Upload successfully created!');
          this.isUploading = false;
          promises = [];
          this.resetForm();
          this.closeModal.next();
        }, (error) => {
          this._toasts.error('Upload has failed!');
          console.error(error.message);
        });
      }
    }
  }

  public resetForm() {
    this.id = null;
    this.files = [];
    this.entry.enable();
    this.disableButtons = false;
    this.defaultParentLocationValue = '';
    this.defaultSubLocationValue = '';
    this.defaultCategoryValue = '';
    this.createForm();
    this.title = String('MODALS.LIBRARIES.ADD.TITLE');
    this.description = String('MODALS.LIBRARIES.ADD.DESCRIPTION');
  }

  public onMatOptionSelect(e: any): void {
    if (e) {
      this.entry.get('parentLocation').patchValue({
        id: e.id
      });
    }
    this.subLocationsFilters = [
      {
        key: 'parent',
        value: true
      },
      {
        key: 'locations',
        value: e.id
      },
    ];
  }

  public onMatOptionSelectSubLocation(e: any): void {
    if (e) {
      this.entry.get('subLocation').patchValue({
        id: e.id
      });
    }
  }

  public onMatOptionSelectCategory(e: any): void {
    if (e) {
      this.entry.get('category').patchValue({
        id: e.id
      });
    }
  }

  public onDismiss() {
    this.resetForm();
    this.afterClose();
  }

  public onGetValueFromAttributes(e: any) {
    if (e instanceof Location) {
      return e.label;
    }
  }

  public addNewFileRow(file) {
    const add = this.entry.get('fileList') as FormArray;
    add.push(this._fb.group({
      label: ['', Validators.required],
      comment: [''],
      file: [file]
    }));
  }

  public deleteNewFileRow(index: number) {
    const add = this.entry.get('fileList') as FormArray;
    add.removeAt(index);
    this.files.splice(index, 1);
  }

  public selectChanges(e: NgxSelectOption[]) {
    const assignedUsers = e.map((ee: NgxSelectOption) => ee.value);
    this.entry.patchValue(assignedUsers);
  }

  public delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /libraries/${id}`;
    this._libraries.delete(id).subscribe(
      (res: ILibrary) => {
        this._logger.info(this.constructorName, url, res);
        this._toasts.success('Document successfully Deleted!');
        this.isLoading = false;
        this.resetForm();
        this.closeModal.next();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        const errors = err.errors;
        if (errors) {
          this.errors = errors;
          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }
      }
    );
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      files: [''],
      fileNames: [''],
      label: [''],
      comment: [''],
      subCategory: [''],
      assignedUsers: [''],
      category: this._fb.group({
        id: ['']
      }),
      isMobileVisible: [false],
      parentLocation: this._fb.group({
        id: ['']
      }),
      subLocation: this._fb.group({
        id: ['']
      }),
      fileList: this._fb.array([]),
      accessPermission: ['']
    });
  }

  private getEmployees() {
    const filters = [
      {
        key: 'pagination',
        value: 'false'
      }
    ];
    const url = 'GET /employees';
    this._employees.get(1, filters).subscribe(
      (res: IEmployee[]) => {
        this._logger.info(this.constructorName, url, res);
        this._contacts.get(1, filters).subscribe(
          (res2: IContact[]) => {
            this._logger.info(this.constructorName, url, res);

            res.forEach((e: any) => {
              this.assignedUsers.push(e.user);
            });
            res2.forEach((e: any) => {
              if (e.user !== undefined) {
                this.assignedUsers.push(e.user);
              }
            });
            this.openDialog();
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);
          }
        );
        this.assignedUsers = res.map((e: IEmployee) => e.user);
        this.openDialog();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private openDialog() {
    this.openModal.subscribe((lib: ILibrary) => {
      if (!!lib) {
        this.defaultParentLocationValue = lib.parentLocation?.label ? lib.parentLocation.label : '';
        this.defaultSubLocationValue = lib.subLocation?.label ? lib.subLocation.label : '';
        this.defaultCategoryValue = lib.category?.name ? lib.category.name : '';
        this.deleteKey = 'BUTTONS.DELETE';
        this.entry.patchValue({
          ...lib,
          assignedUsers: lib.assignedUsers.map((u: IUser) => u.id)
        });
      } else {
        this.deleteKey = null;
      }
    });
  }
}
