import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { recurrenceForSteps, recurrences } from '@shared/helpers/types.helper';
import { IAddress } from '@shared/interfaces/address.interface';
import { IAssetGroup } from '@shared/interfaces/asset-group.interface';
import { IAsset } from '@shared/interfaces/asset.interface';
import { IEvent } from '@shared/interfaces/event.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Event } from '@shared/models/event.model';
import { Location } from '@shared/models/location.model';
import { Template } from '@shared/models/template.model';
import { AddressesService } from '@shared/services/addresses.service';
import { AssetGroupsService } from '@shared/services/assets-group.service';
import { AssetsService } from '@shared/services/assets.service';
import { EmployeesService } from '@shared/services/employees.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { EventsService } from '@shared/services/events.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationEventsService } from '@shared/services/pagination/pagination.events.service';
import { TemplatesService } from '@shared/services/templates.service';
import { ToastService } from '@shared/services/toast.service';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import tzlookup from 'tz-lookup';
@Component({
  selector: 'app-modals-events',
  templateUrl: './modals.events.component.html',
  styleUrls: ['./modals.events.component.scss']
})
export class ModalsEventsComponent
  extends ModalDirective<IEvent>
  implements OnInit {
  @Input() public openModal: Subject<IEvent>;

  public defaultLocationValue: string;
  public defaultParentValue: string;
  public defaultTemplateValue: string;
  public defaultUserValue: string;
  public timezone = String('');
  public timezoneLocation = String('');
  public asset: Partial<IAsset>[] = [];

  public title = String('MODALS.EVENTS.ADD.TITLE');
  public description = String('MODALS.EVENTS.ADD.DESCRIPTION');

  public type = String('event');
  public recurrences: { value: string; label: string }[] = recurrences;

  public entry: FormGroup;
  public disableButtons = Boolean(false);
  public isEmployee = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public locations: ILocation[] = [];
  public users: IUser[] = [];
  public startDate: string;
  public dueDate: string;
  public event: IEvent;
  public assetGroups: Partial<IAssetGroup>[] = [];

  public config: any = {
    class: 'modal-lg'
  };

  public locationsFilters: Partial<IFilter>[] = [
    {
      key: 'parent',
      value: 'true'
    }
  ];

  public eventsFilters: Partial<IFilter>[] = [
    {
      key: 'isPaused',
      value: 'true'
    }
  ];

  public bsConfig: any = {
    containerClass: 'ohq-datepicker',
    dateInputFormat: 'MMMM DD YYYY',
    showWeekNumbers: false,
    adaptivePosition: true,
    minMode: 'day'
  };

  public maxDate;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    public readonly _assets: AssetsService,
    public readonly _events: EventsService,
    public readonly _locations: LocationsService,
    public readonly _templates: TemplatesService,
    public readonly _employees: EmployeesService,
    public readonly _addresses: AddressesService,
    public readonly _assetGroups: AssetGroupsService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _toasts: ToastService,
    private readonly _paginationEvents: PaginationEventsService,
  ) {
    super();

    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }

  ngOnInit(): void {
    const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
    this.isEmployee = Boolean(payload.employeeRole === 3);
    this.createForm();
    this.getAssets();
    this.getAssetGroups();

    this.openModal.subscribe((e: IEvent) => {
      if (!!e?.id) {
        this.event = e;
        this.title = 'MODALS.EVENTS.EDIT.TITLE';
        this.description = 'MODALS.EVENTS.EDIT.DESCRIPTION';
        this.defaultLocationValue = e.location.label;
        this.defaultTemplateValue = e.template.label;
        this.defaultUserValue = `${e.user.fullName}`;
        this.defaultParentValue = !!e.parent ? e.parent.label : null;

        const isCustomSelected = e.step > 1;

        const startDate = moment(e.startDate * 1000);
        const dueDate = moment(e.dueDate * 1000);

        this.entry.patchValue({
          id: e.id,
          label: e.label,
          recurrence: e.recurrence,
          step: e.step,
          isWeekendExcluded: e.isWeekendExcluded,
          isAllowSubmissionAfterDue: e.isAllowSubmissionAfterDue,
          isFollowUp: !!e.parent,
          parent: !!e.parent ? e.parent : { id: '' },
          user: e.user,
          template: e.template,
          location: e.location,
          isCustomSelected,
          startDate: startDate.toDate(),
          dueDate: dueDate.toDate(),
          asset: e.asset?.map((item: any) => !!item?.id ? item?.id : item?.Id),
          assetGroups: e?.assetGroups?.map((item: any) => !!item?.Id ? item?.Id : item?.id ? item?.id : item),
        });
        this._addresses.findByLocation(e.location.parent as string).subscribe((r: IAddress) => {
          let t = tzlookup(r.lat, r.lng) as any;
          if (!t) {
            t = '';
          }
          this.timezone = moment.tz(t).format('z');
          this.timezoneLocation = t;
          this.bsConfig = {
            ...this.bsConfig,
            timeZone: this.timezoneLocation,
          };
          this.entry.patchValue(
            {
              startDateTime: moment(e.startDate * 1000).toDate(),
              dueDateTime: moment(e.dueDate * 1000).toDate()
            },
            {
              emitEvent: false,
              onlySelf: true
            }
          );

          this.startDate = moment
            .unix(e.startDate)
            .tz(t)
            .format('lll');
          this.dueDate = moment
            .unix(e.dueDate)
            .tz(t)
            .format('lll');
        });
      } else {

        if (e?.asset) {
          this.event = {
            ...e,
          };
        }

        this.defaultLocationValue = e?.location?.label ? e?.location?.label : null;
        this.defaultParentValue = !!e?.parent ? e.parent.label : null;

        this.entry.patchValue({
          parent: !!e?.parent ? e.parent : { id: '' },
          location: !!e?.location ? e.location : { id: '' },
          asset: e?.asset?.map((item: any) => !!item?.id ? item?.id : item?.Id),
          assetGroups: e?.assetGroups?.map((item: any) => !!item?.Id ? item?.Id : item?.id ? item?.id : item),
        });
        this.defaultTemplateValue = undefined;
        this.defaultUserValue = undefined;

        this.title = 'MODALS.EVENTS.ADD.TITLE';
        this.description = 'MODALS.EVENTS.ADD.DESCRIPTION';

        if (this.isEmployee) {
          this.entry.patchValue({ user: { id: payload.id } });
        }

        this.entry.patchValue({
          step: 1,
          isWeekendExcluded: true,
          isFollowUp: false,
          startDate: moment().toDate(),
          dueDate: moment().toDate(),
          recurrence: 'd',
          startDateTime: moment().toDate(),
          dueDateTime: moment().toDate(),
        });

        const value = this.entry.getRawValue();
        value.startDate = moment(value.startDate).toDate();
        value.startDateTime = moment(value.startDateTime).toDate();
        value.dueDate = moment(value.dueDate).toDate();
        value.dueDateTime = moment(value.dueDateTime).toDate();
        const sD =
          new Date(
            value.startDate.getFullYear(),
            value.startDate.getMonth(),
            value.startDate.getDate(),
            value.startDateTime.getHours(),
            value.startDateTime.getMinutes(),
            value.startDateTime.getSeconds()
          ).getTime() / 1000;
        const dD =
          new Date(
            value.dueDate.getFullYear(),
            value.dueDate.getMonth(),
            value.dueDate.getDate(),
            value.dueDateTime.getHours(),
            value.dueDateTime.getMinutes(),
            value.dueDateTime.getSeconds()
          ).getTime() / 1000;

        this.startDate = moment(moment(sD * 1000).toDate()).format('lll');
        this.dueDate = moment(moment(dD * 1000).toDate()).format('lll');
        this.changeRecurrence();
      }

      this.entry.valueChanges.subscribe(() => {
        const value = this.entry.getRawValue();
        value.startDate = moment(value.startDate).toDate();
        value.startDateTime = moment(value.startDateTime).toDate();
        value.dueDate = moment(value.dueDate).toDate();
        value.dueDateTime = moment(value.dueDateTime).toDate();
        const sD =
          new Date(
            value.startDate.getFullYear(),
            value.startDate.getMonth(),
            value.startDate.getDate(),
            value.startDateTime.getHours(),
            value.startDateTime.getMinutes(),
            value.startDateTime.getSeconds()
          ).getTime() / 1000;
        const dD =
          new Date(
            value.dueDate.getFullYear(),
            value.dueDate.getMonth(),
            value.dueDate.getDate(),
            value.dueDateTime.getHours(),
            value.dueDateTime.getMinutes(),
            value.dueDateTime.getSeconds()
          ).getTime() / 1000;

        this.startDate = moment(moment(sD * 1000).toDate()).format('lll');
        this.dueDate = moment(moment(dD * 1000).toDate()).format('lll');
        if (this.timezoneLocation) {
          this.startDate = moment(this.startDate).tz(this.timezoneLocation).format('lll');
          this.dueDate = moment(this.dueDate).tz(this.timezoneLocation).format('lll');

        }
      });
    });
  }

  // public getStartDate() {
  //   return this.startDate = moment(this.startDate).tz(this.timezoneLocation).format('lll');
  // }

  // public getDueDate() {
  //   return this.dueDate = moment(this.dueDate).tz(this.timezoneLocation).format('lll');
  // }

  public getAssets() {
    const url = `GET /assets`;
    this._assets.get(1, []).subscribe(
      (res: IAsset[]) => {
        this.asset = res;
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public getAssetGroups() {
    const url = `GET /asset-groups`;
    this._assetGroups.get(1, []).subscribe(
      (res: IAssetGroup[]) => {
        this.assetGroups = res;
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public changeFollowUp() {
    const parent = this.entry.controls['parent'];
    const recurrence = this.entry.controls['recurrence'];
    const dueDate = this.entry.controls['dueDate'];
    const isWeekendExcluded = this.entry.controls['isWeekendExcluded'];
    const isCustomSelected = this.entry.controls['isCustomSelected'];

    const isFollowUp = this.entry.get('isFollowUp').value;

    if (!isFollowUp) {
      this.entry.get('parent').patchValue({
        id: ''
      });

      this.entry.patchValue({
        isCustomSelected: false
      });

      parent.setValidators([]);
      recurrence.enable();
      isCustomSelected.enable();

      if (this.entry.get('recurrence').value !== 'o') {
        dueDate.enable();
      }
    } else {
      this.entry.patchValue({
        recurrence: 'o',
        isCustomSelected: false
      });

      parent.setValidators([Validators.required]);
      isWeekendExcluded.disable();
      isCustomSelected.disable();
      recurrence.disable();
      dueDate.disable();

      this.recurrences = recurrences;
    }

    parent.updateValueAndValidity();
    dueDate.updateValueAndValidity();
  }

  public changeCustom() {
    const isCustomSelected = this.entry.get('isCustomSelected').value;

    if (!!isCustomSelected) {
      this.recurrences = recurrenceForSteps;
      const step = this.entry.get('step').value;
      const recurrence = recurrences.find((r: any) => r.value === 'w');
      const label = recurrence.label.replace(' ', ` ${step} `);

      this.entry.patchValue({
        isWeekendExcluded: false,
        recurrence: recurrence.value,
        label
      });
    } else {
      const recurrence = recurrences.find((r: any) => r.value === 'w');

      this.recurrences = recurrences;
      this.entry.patchValue({
        step: 1,
        label: recurrence.label,
        isWeekendExcluded: true
      });
    }

    this.entry.controls['dueDate'].enable();
  }

  public changeRecurrence() {
    const recurrence = this.entry.get('recurrence').value;
    const isFollowUp = this.entry.controls['isFollowUp'];
    const dueDateControls = this.entry.controls['dueDate'];

    const isWeekendExcluded = this.entry.controls['isWeekendExcluded'];

    let startDate;
    let dueDate;

    switch (recurrence) {
      case 'M':
        this.bsConfig = {
          ...this.bsConfig,
          minMode: 'day',
          dateInputFormat: 'MMMM DD YYYY',
        };

        startDate = moment()
          .startOf('month')
          .set({ hour: 8, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();

        dueDate = moment()
          .endOf('month')
          .set({ hour: 17, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();
        break;

      case 'y':
        this.bsConfig = {
          ...this.bsConfig,
          minMode: 'month',
          dateInputFormat: 'MMMM DD YYYY',
        };

        startDate = moment()
          .startOf('year')
          .set({ hour: 8, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();

        dueDate = moment()
          .endOf('year')
          .set({ hour: 17, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();
        break;

      case 'w':
        this.bsConfig = {
          ...this.bsConfig,
          minMode: 'day',
          dateInputFormat: 'MMMM DD YYYY',
        };

        startDate = moment()
          .startOf('isoWeek')
          .set({ hour: 8, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();

        dueDate = moment(startDate)
          .add(4, 'd')
          .set({ hour: 17, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();
        break;

      case 'd':
        this.bsConfig = {
          ...this.bsConfig,
          minMode: 'day',
          dateInputFormat: 'MMMM DD YYYY',
        };

        startDate = moment()
          .set({ hour: 8, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();

        dueDate = moment()
          .set({ hour: 17, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();
        break;

      case 'o':
        this.bsConfig = {
          ...this.bsConfig,
          minMode: 'day',
          dateInputFormat: 'MM-DD-YYYY'
        };

        startDate = moment()
          .set({ hour: 8, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();

        dueDate = moment()
          .set({ hour: 17, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();
        break;

      case 'h':
        this.bsConfig = {
          ...this.bsConfig,
          minMode: 'day',
          dateInputFormat: 'MM-DD-YYYY'
        };

        startDate = moment()
          .set({ hour: 8, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();

        dueDate = moment()
          .set({ hour: 17, minute: 0, second: 0 })
          // .tz(this._commonEnvironments.TZ)
          .toDate();
        break;
    }

    if (recurrence !== 'o') {
      dueDateControls.enable();
      isFollowUp.disable();
      isWeekendExcluded.enable();
    } else {
      dueDateControls.disable();
      isFollowUp.enable();
      isWeekendExcluded.disable();
    }

    this.entry.patchValue({
      startDate,
      dueDate,
      startDateTime: startDate,
      dueDateTime: dueDate
    });

    // this.startDate = moment( moment(startDate).toDate()).format('lll');
    // this.dueDate = moment( moment(dueDate).toDate()).format('lll');
  }



  // public selectedDate() {
  //   const startDate = this.entry.get('startDate').value;
  //   this.startDate = moment(startDate).format('lll');
  // }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();
      this.disableButtons = true;

      let method = 'post';
      let url = `${method.toUpperCase()} /events`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /events/${value.id}`;
      }

      value = this.entry.getRawValue();
      value.startDate = moment(value.startDate).toDate();
      value.startDateTime = moment(value.startDateTime).toDate();
      value.dueDate = moment(value.dueDate).toDate();
      value.dueDateTime = moment(value.dueDateTime).toDate();

      const startDate =
        new Date(
          value.startDate.getFullYear(),
          value.startDate.getMonth(),
          value.startDate.getDate(),
          value.startDateTime.getHours(),
          value.startDateTime.getMinutes(),
          value.startDateTime.getSeconds()
        ).getTime() / 1000;

      const dueDate =
        new Date(
          value.dueDate.getFullYear(),
          value.dueDate.getMonth(),
          value.dueDate.getDate(),
          value.dueDateTime.getHours(),
          value.dueDateTime.getMinutes(),
          value.dueDateTime.getSeconds()
        ).getTime() / 1000;

      if (Array.isArray(value.asset) && value.asset.length > 0) {
        value.asset = value.asset;
      } else {
        value.asset = [];
      }

      value = {
        ...value,
        startDate,
        dueDate,
      };

      this._events[method](value).subscribe(
        (res: IEvent) => {
          this._logger.info(this.constructorName, url, res);
          this._paginationEvents.reloadPage(1);
          this.resetModal(res);
          this.setDefaultValues();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;
          if (errors) {
            this.errors = errors;

            if (errors[0].detail) {
              this._toasts.error(errors[0].detail);
            }
          }

          this.entry.enable();
          this.disableButtons = false;
        }
      );
    }
  }

  public onDismiss() {
    this.errors = [];

    this.entityForm?.reset();
    this.setDefaultValues();
  }

  public updateDates() {
    const value = this.entry.getRawValue();
    value.startDate = moment(value.startDate).toDate();
    value.startDateTime = moment(value.startDateTime).toDate();
    value.dueDate = moment(value.dueDate).toDate();
    value.dueDateTime = moment(value.dueDateTime).toDate();
    const sD =
      new Date(
        value.startDate.getFullYear(),
        value.startDate.getMonth(),
        value.startDate.getDate(),
        value.startDateTime.getHours(),
        value.startDateTime.getMinutes(),
        value.startDateTime.getSeconds()
      ).getTime() / 1000;
    const dD =
      new Date(
        value.dueDate.getFullYear(),
        value.dueDate.getMonth(),
        value.dueDate.getDate(),
        value.dueDateTime.getHours(),
        value.dueDateTime.getMinutes(),
        value.dueDateTime.getSeconds()
      ).getTime() / 1000;

    this.startDate = moment(moment(sD * 1000).toDate()).format('lll');
    this.dueDate = moment(moment(dD * 1000).toDate()).format('lll');
    if (this.timezoneLocation) {
      this.startDate = moment(this.startDate).tz(this.timezoneLocation).format('lll');
      this.dueDate = moment(this.dueDate).tz(this.timezoneLocation).format('lll');

    }
  }

  public onMatOptionSelect(e: any): void {
    let type = 'user';
    let id = e.id;

    if (e instanceof Location) {
      type = 'location';
      this._addresses.findByLocation(e.parent.id).subscribe((r: IAddress) => {
        let t = tzlookup(r.lat, r.lng) as any;
        if (!t) {
          t = '';
        }
        this.timezone = moment.tz(t).format('z');
        this.timezoneLocation = t;
        this.bsConfig = {
          ...this.bsConfig,
          timeZone: this.timezoneLocation
        };
        this.updateDates();

      });
    } else if (e instanceof Template) {
      type = 'template';
    } else if (e instanceof Event) {
      type = 'parent';

      this.entry.patchValue({
        location: e.location,
        template: e.template
      });
    } else {
      // User
      id = e.user.id;
    }

    this.entry.get(type).patchValue({
      id
    });
  }

  public onGetValueFromAttributes(e: any) {
    if (e instanceof Template || e instanceof Location) {
      return e.label;
    } else if (e instanceof Event) {
      return `${e.label} (${moment(e.startDate * 1000).format('ll')})`;
    } else {
      return `${e.fullName} ${e.email ? '(' + e.email + ')' : null}`;
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      label: ['', [Validators.required]],
      recurrence: ['', [Validators.required]],
      step: ['', [Validators.required]],
      isWeekendExcluded: [true],
      isAllowSubmissionAfterDue: [false],
      isFollowUp: [false],
      isCustomSelected: [false],
      startDate: ['', [Validators.required]],
      startDateTime: ['', [Validators.required]],
      dueDate: ['', []],
      dueDateTime: ['', []],
      parent: this._fb.group({
        id: ['']
      }),
      user: this._fb.group({
        id: ['', [Validators.required]]
      }),
      template: this._fb.group({
        id: ['', [Validators.required]]
      }),
      location: this._fb.group({
        id: ['', [Validators.required]]
      }),
      asset: [''],
      assetGroups: ['', []],
    });
  }

  private setDefaultValues() {
    this.disableButtons = false;
    this.defaultLocationValue = undefined;
    this.defaultTemplateValue = undefined;
    this.defaultUserValue = undefined;

    this.entry.enable();

    this.entry.patchValue({
      step: 1,
      isAllowSubmissionAfterDue: false,
      isWeekendExcluded: true,
      isCustomSelected: false,
      isFollowUp: false,
      dueDate: null
    });

    this.recurrences = recurrences;
  }
}
