import { environment } from '@environment/environment';
import { getFormattedDate } from '@shared/helpers/status.helper';
import { getConvertedDate, getConvertedDateHours } from '@shared/helpers/timezone.helper';
import { IAddress } from '@shared/interfaces/address.interface';
import { IAssetGroup } from '@shared/interfaces/asset-group.interface';
import { IAsset } from '@shared/interfaces/asset.interface';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { IEvent } from '@shared/interfaces/event.interface';
import { IItem } from '@shared/interfaces/item.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Address } from '@shared/models/address.model';
import { Item } from '@shared/models/item.model';
import { Location } from '@shared/models/location.model';
import { Template } from '@shared/models/template.model';
import { User } from '@shared/models/user.model';
import moment from 'moment-timezone';

export class Checklist implements IChecklist {
  public id: string;
  public antecedent: Partial<IChecklist>;
  public parentLocation: Partial<ILocation>;
  public location: Partial<ILocation>;
  public manager?: IUser;
  public template: ITemplate;
  public address: IAddress;
  public event: Partial<IEvent>;
  public submittedBy: Partial<IUser>;
  public reviewedBy: Partial<IUser>;
  public previousChecklist: Partial<IChecklist>;
  public assets: Partial<IAsset>[];
  public assetGroups: Partial<IAssetGroup>[];
  public jobId?: string;
  public label: string;
  public items: IItem[];
  public notes: string;
  public lat: number;
  public lng: number;
  public firstCompletionDate: Date;

  public isDeleted: boolean;
  public isLate: boolean;

  public submittedAt: Date;
  public scheduledAt: Date;
  public createdAt: Date;
  public reviewedAt: Date;
  public scheduledTo: Date;

  constructor(partial: Partial<IChecklist>) {
    Object.assign(this, {
      ...partial,
      antecedent:
        typeof partial?.antecedent === 'object'
          ? new Checklist(partial.antecedent)
          : partial?.antecedent,
      address:
        typeof partial?.address === 'object'
          ? new Address(partial.address)
          : partial?.address,
      items:
        typeof partial?.items === 'object'
          ? partial.items.map((i: IItem) => new Item(i))
          : [],
      location:
        typeof partial?.location === 'object'
          ? new Location(partial.location)
          : partial?.location,
      manager:
        typeof partial?.manager === 'object'
          ? new User(partial.manager)
          : partial?.manager,
      parentLocation:
        typeof partial?.parentLocation === 'object'
          ? new Location(partial.parentLocation)
          : partial?.parentLocation,
      template:
        typeof partial?.template === 'object'
          ? new Template(partial.template)
          : partial?.template,
      submittedBy:
        typeof partial?.submittedBy === 'object'
          ? new User(partial.submittedBy)
          : partial?.submittedBy,
      reviewedBy:
        typeof partial?.reviewedBy === 'object' && partial?.reviewedBy !== null
          ? new User(partial.reviewedBy)
          : partial?.reviewedBy,
      previousChecklist:
        typeof partial?.previousChecklist === 'object' &&
          partial?.previousChecklist !== null
          ? new Checklist(partial.previousChecklist)
          : null
    });
  }

  get scheduledAtLabel(): string {
    if (!this.scheduledAt) {
      return '-';
    }

    const recurrence = this.event?.recurrence;
    return getFormattedDate(
      moment(this.scheduledAt).unix(),
      recurrence,
      0,
      true
    );
  }

  get convertedScheduledTo(): string {
    if (this.scheduledTo) {
      return getConvertedDate(this.scheduledTo, this.event.address[0].lat, this.event.address[0].lng);
    }
    if (this.submittedAt !== null) {
      const datePeriod = getConvertedDateHours(this.event.startDate * 1000, this.event.dueDate * 1000, this.address[0].lat,
        this.address[0].lng);
      let recurrence = '';
      if (this.event.recurrence === 'o') { recurrence = 'One Time'; };
      if (this.event.recurrence === 'h') { recurrence = 'Hourly'; };
      if (this.event.recurrence === 'd') { recurrence = 'Daily'; };
      if (this.event.recurrence === 'w') { recurrence = 'Weekly'; };
      if (this.event.recurrence === 'M') { recurrence = 'Monthly'; };
      if (this.event.recurrence === 'y') { recurrence = 'Yearly'; };
      return `${recurrence} - ${datePeriod}
      `;
    }
    return '';
  }

  get actions(): string[] {
    return this.items.map((i: any) => i.actions).flat();
  }

  get hasActionsOrNos() {
    if (!!this.reviewedAt) {
      return {
        isEnabled: false
      };
    }

    const nos = this.items.some((i: any) => i.value === '2' && i.type === 0);
    const actions = this.items.some((i: any) => i.actions.length > 0);

    let label;
    let className;

    if (actions) {
      label = 'Action(s) required';
      className = 'lnr-warning text-warning flash';
    }

    if (nos) {
      label = 'Issues flagged';
      className = 'lnr-notification text-danger flash';
    }

    if (actions && nos) {
      label = 'Action(s) required + Issues flagged';
      className = 'lnr-warning text-danger flash';
    }

    return {
      isEnabled: actions || nos,
      class: className,
      label
    };
  }

  get itemsCount(): number {
    return this.items.length;
  }

  get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }

  get pillsStatus() {
    let label = 'STATUS.VERIFY';
    let className = 'ohq-badge-warning';

    if (this.reviewedAt) {
      label = 'STATUS.COMPLETED';
      className = 'ohq-badge-success';
    } else if (!this.submittedAt) {
      label = 'STATUS.MISSED';
      className = 'ohq-badge-danger';
    }

    return {
      label,
      class: className
    };
  }

  get assignedTo(): string {
    if (!this.submittedAt) {
      const result = this.event.user ? this.event.user.firstName + ' ' + this.event.user.lastName : '';
      return result;

    }
    return this.submittedBy.fullName;
  }

  get customerNumber(): string {
    const customerNumberItem = this.items.find(item => item.label === 'Enter the Customer Number' || item.label === 'Entrez le numéro du client');
    if (customerNumberItem) {
      return customerNumberItem.value
    }
    return ''
  }
}
