import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICompanySetting } from '@shared/interfaces/company-setting.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import rrulePlugin from '@fullcalendar/rrule';
import jwtDecode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { CalendarOptions, DateSelectArg, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { RecurringTaskService } from '@shared/services/recurring-tasks.service';
import { LoggerService } from '@shared/services/logger.service';
import { EventsService } from '@shared/services/events.service';
import { TasksService } from '@shared/services/tasks.service';
import * as moment from 'moment';
import { IRecurringTask } from '@shared/interfaces/recurring-task.interface';
import { ChecklistsService } from '@shared/services/checklists.service';
import { rrulestr } from 'rrule';
import { Subject } from 'rxjs';
import { ITask } from '@shared/interfaces/task.interface';
import { ToastService } from '@shared/services/toast.service';
import { AddressesService } from '@shared/services/addresses.service';
import { Task } from '@shared/models/task.model';
import { getTimezoneShort } from '@shared/helpers/timezone.helper';
import tzlookup from 'tz-lookup';
import { FullCalendarComponent } from '@fullcalendar/angular';
import allLocales from '@fullcalendar/core/locales-all'; // Import all locales
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  @LocalStorage() public readonly companySettings: ICompanySetting;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @Input() selectedLocationId: any;
  @Input() selectedSubLocationId: any;

  public title = String('CALENDAR.LIST.TITLE');
  public description = String('CALENDAR.LIST.DESCRIPTION');
  public iconClass = String('lnr-calendar');
  public type = String('calendar');
  public counter: number;
  public isLoading: Boolean = false;
  public openTasksModal: Subject<ITask> = new Subject();
  public openRecurringTasksModal: Subject<ITask> = new Subject();
  public openDeleteModal: Subject<boolean> = new Subject();
  public calendarData: any;
  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      rrulePlugin
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'timeGridWeek',
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    allDaySlot: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    datesSet: this.handleDates.bind(this),
    locale: this._translate.currentLang, // Change to the desired language, e.g., 'es', 'de', 'pt-br'
    locales: allLocales, // Ensure all locales are available
    // dateClick: this.handleDateClick.bind(this),
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  currentEvents: EventApi[] = [];

  private readonly constructorName: string = String(this.constructor.name);
  constructor(
    private readonly _router: Router,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private changeDetector: ChangeDetectorRef,
    private readonly _recurringTasks: RecurringTaskService,
    private readonly _checklistsService: ChecklistsService,
    private readonly _tasksService: TasksService,
    private readonly _eventsService: EventsService,
    private readonly _logger: LoggerService,
    private readonly _ar: ActivatedRoute,
    private readonly _tasks: TasksService,
    private readonly _toasts: ToastService,
    public readonly _addresses: AddressesService,
    private readonly _translate: TranslateService
  ) {
    this.afterSubmit = this.afterSubmit.bind(this);
    this.afterClose = this.afterClose.bind(this);
  }

  ngOnChanges() {
    this.handleDates();
  }

  ngOnInit(): void {
    const token = this._commonEnvironments.getToken();
    const payload: IPayload = jwtDecode(token);
    if (payload.employeeRole === 5) {
      this._router.navigate(['/tasks']);
      return;
    }
    const lastMonth = new Date();
    lastMonth.setMonth(new Date().getMonth() - 1);
    const nextMonth = new Date();
    nextMonth.setMonth(new Date().getMonth() + 1);
    this.isLoading = true;
    this.getData(lastMonth.getTime() / 1000, nextMonth.getTime() / 1000);
    this._translate.onLangChange.subscribe(() => {
      this.calendarOptions = {
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          rrulePlugin
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        initialView: 'timeGridWeek',
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        weekends: true,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        allDaySlot: true,
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        datesSet: this.handleDates.bind(this),
        locale: this._translate.currentLang, // Change to the desired language, e.g., 'es', 'de', 'pt-br'
        locales: allLocales, // Ensure all locales are available
        // dateClick: this.handleDateClick.bind(this),
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      };
    });
  }


  public getData(from: number, to: number) {
    this._recurringTasks
      .get(1, [
        { key: 'pagination', value: false },
        { key: 'startDateCalendar', value: from },
        { key: 'endDateCalendar', value: to },
        { key: 'parent', value: this.selectedLocationId },
        { key: 'selectedSubLocationId', value: this.selectedSubLocationId },
        { key: 'limit', value: 1000 },
        { key: 'ignorePaused', value: true}
      ])
      .subscribe(
        (res: any) => {
          this._logger.info(this.constructorName, '/recurring/tasks', res);
          const recurringTasksEvents = [];
          res.forEach((item) => {
            item.isRecurringTask = true;
            item.scheduledDates.forEach( event => {
              recurringTasksEvents.push({
                ...item,
                startDate: event.startDate,
                dueDate: event.dueDate
              });
            });
          });
          this._tasksService
            .get(1, [
              { key: 'pagination', value: false },
              { key: 'startDateCalendar', value: from },
              { key: 'endDateCalendar', value: to },
              { key: 'owner', value: 'all' },
              { key: 'parent', value: this.selectedLocationId },
              { key: 'selectedSubLocationId', value: this.selectedSubLocationId },
              { key: 'limit', value: 1000 }
            ])
            .subscribe(
              (res2: any) => {
                this.calendarData = this.mapCalendarData([...recurringTasksEvents, ...res2]);
                // setTimeout(() => {
                //   this.calendarOptions.initialEvents = [];
                  this.calendarOptions.initialEvents = this.calendarData;
                // }, 1000);
                if (this.calendarComponent) {
                  this.calendarOptions.initialEvents = [];
                  this.calendarOptions.events = this.calendarData;
                }
                this.isLoading = false;
                setTimeout(() => {
                  if(document.getElementsByClassName('fc-timegrid-axis-frame')){
                    if(document.getElementsByClassName('fc-timegrid-axis-frame')[0]) {
                      document.getElementsByClassName('fc-timegrid-axis-frame')[0].innerHTML = getTimezoneShort(Intl.DateTimeFormat().resolvedOptions().timeZone);
                    }
                  };
                }, 1000);

              },
              (err: any) => {
                this._logger.error(this.constructorName, '/tasks', err);
                this.isLoading = false;
              }
            );
        },
        (err: any) => {
          this._logger.error(this.constructorName, '/events', err);
          this.isLoading = false;
        }
      );
  }

  public mapCalendarData(data: any[]) {
    data = data.filter(
      (item) =>
        item.startDate &&
        item.dueDate &&
        new Date(item.startDate * 1000) instanceof Date &&
        new Date(item.dueDate * 1000) instanceof Date &&
        new Date(item.dueDate * 1000) > new Date(item.startDate * 1000)
    );

    return data.map((item) => {
      const event = {
        id: item.id,
        title:
          ' ' +
          (item.subject || item.task) +
          ', ' +
          (item?.location?.label || item?.subLocation?.label
            ? item?.location?.label || item?.subLocation?.label
            : '') +
          (item?.user?.firstName ? '- ' + item?.user?.firstName : ''),
        start: new Date(item.startDate * 1000),
        end: new Date(item.dueDate * 1000),
        data: item
      } as any;

      event.backgroundColor = '#0052CC';
      event.borderColor = '#0052CC';

      if (item?.status === 'OPEN' || item?.status === 'ACTIVE') {
        event.backgroundColor = '#006af89c';
        event.borderColor = '#006af89c';
      } else if (item?.status === 'IN_PROGRESS') {
        event.backgroundColor = '#4d4dff';
        event.borderColor = '#4d4dff';
      } else if (item?.status === 'ON_HOLD' || item?.status === 'PAUSED') {
        event.backgroundColor = '#f57f17';
        event.borderColor = '#f57f17';
      } else if (item?.status === 'CLOSED') {
        event.backgroundColor = '#000';
        event.borderColor = '#000';
      } else if (item?.status === 'COMPLETED') {
        event.backgroundColor = '#36B37E';
        event.borderColor = '#36B37E';
      }

      

      return event;
    });
  }

  ngOnDestroy(): void {
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const t = JSON.parse(JSON.stringify(selectInfo));
    t.startDate = new Date(t.startStr).getTime() / 1000;
    t.dueDate = new Date(t.endStr).getTime() / 1000;
    t.status = 'OPEN';
    t.customizeDates = true;
    const task = new Task(t);
    this.openTasksModal.next(task);
  }

  public afterClose() {
  }

  public afterSubmit() {

    this.getData(new Date(this.calendarComponent.getApi().getCurrentData().dateProfile.currentRange.start).getTime() / 1000, new Date(this.calendarComponent.getApi().getCurrentData().dateProfile.currentRange.end).getTime() / 1000);
  }

  handleEventClick(clickInfo: any) {
    const t = JSON.parse(
      JSON.stringify(clickInfo.event._def.extendedProps.data)
    );
    t.startDate =
      new Date(
        clickInfo.event._def.extendedProps.data.startDate * 1000
      ).getTime() / 1000;
    t.dueDate =
      new Date(
        clickInfo.event._def.extendedProps.data.dueDate * 1000
      ).getTime() / 1000;
    const task = new Object(t) as any;
    if (task.isRecurringTask) {
      this.openRecurringTasksModal.next(task);
    } else {
      this.openTasksModal.next(task);
    }
  }

  handleDates() {
    if (this.calendarComponent) {
      this.getData(new Date(this.calendarComponent.getApi().getCurrentData().dateProfile.currentRange.start).getTime() / 1000, new Date(this.calendarComponent.getApi().getCurrentData().dateProfile.currentRange.end).getTime() / 1000);
    }
  }

  // handleDateClick(arg) {
  //   alert('date click! ' + arg.dateStr)
  // }
}
